import { PlatformAnalyticsData } from '../../../redux/slices/analyticsSlice';
import { COMPARED_TIMEFRAME_LABEL_MAPPING } from '../AnalyticsConstants';
import moment from 'moment';
import icons from '../../../style';

export const chartColorPalette = ['#0066FF', '#217AFF', '#428EFF', '#63A2FF', '#84B6FF', '#A5CAFF', '#C6DEFF', '#E5F0FF'];

export const WIDGET_TYPES = {
    CHARTLESS: 'CHARTLESS',
    PIE: 'PIE',
    BAR: 'BAR',
    TABLE: 'TABLE'
} as const;

export type WidgetTypes = typeof WIDGET_TYPES[keyof typeof WIDGET_TYPES];
export const CHART_KEYS = {
    sessions: 'sessions',
    unique_devices: 'unique_devices',
    unique_customers: 'unique_customers',
    average_duration: 'average_duration',
    average_playback_session: 'average_playback_session',
    average_playback_try: 'average_playback_try',
    average_session_duration: 'average_session_duration',
    device_category: 'device_category',
    device_type: 'device_type',
    device_manufacturer: 'device_manufacturer',
    device_model: 'device_model',
    os: 'os',
    os_version: 'os_version',
    client_version: 'client_version',
    geo_country: 'geo_country',
    movie_title: 'movie_title'
} as const;

export const CHART_KEY_TO_INITIAL_WIDGET_TYPE_MAPPING: any = {
    [CHART_KEYS.sessions]: WIDGET_TYPES.CHARTLESS,
    [CHART_KEYS.unique_devices]: WIDGET_TYPES.CHARTLESS,
    [CHART_KEYS.unique_customers]: WIDGET_TYPES.CHARTLESS,
    [CHART_KEYS.average_duration]: WIDGET_TYPES.CHARTLESS,
    [CHART_KEYS.average_playback_session]: WIDGET_TYPES.CHARTLESS,
    [CHART_KEYS.average_playback_try]: WIDGET_TYPES.CHARTLESS,
    [CHART_KEYS.average_session_duration]: WIDGET_TYPES.CHARTLESS,
    [CHART_KEYS.device_category]: WIDGET_TYPES.PIE,
    [CHART_KEYS.device_type]: WIDGET_TYPES.PIE,
    [CHART_KEYS.device_manufacturer]: WIDGET_TYPES.PIE,
    [CHART_KEYS.device_model]: WIDGET_TYPES.TABLE,
    [CHART_KEYS.os]: WIDGET_TYPES.PIE,
    [CHART_KEYS.os_version]: WIDGET_TYPES.BAR,
    [CHART_KEYS.client_version]: WIDGET_TYPES.PIE,
    [CHART_KEYS.geo_country]: WIDGET_TYPES.PIE,
    [CHART_KEYS.movie_title]: WIDGET_TYPES.TABLE
};
export const CHART_KEYS_TO_TILE_MAPPING = {
    [CHART_KEYS.sessions]: 'Total Sessions',
    [CHART_KEYS.unique_devices]: 'Unique Devices',
    [CHART_KEYS.unique_customers]: 'Unique Customers',
    [CHART_KEYS.average_duration]: 'Time to first playback',
    [CHART_KEYS.average_playback_session]: 'Average playback time (per session)',
    [CHART_KEYS.average_playback_try]: 'Average playback time (per try)',
    [CHART_KEYS.average_session_duration]: 'Average Session Duration',
    [CHART_KEYS.device_category]: 'Device Type',
    [CHART_KEYS.device_type]: 'Device Subtype',
    [CHART_KEYS.device_manufacturer]: 'Device Manufacturer',
    [CHART_KEYS.device_model]: 'Device Model',
    [CHART_KEYS.os]: 'OS',
    [CHART_KEYS.os_version]: 'OS Version',
    [CHART_KEYS.client_version]: 'Client Version',
    [CHART_KEYS.geo_country]: 'Split of countries',
    [CHART_KEYS.movie_title]: 'Most watched videos'
} as const;

export const WidgetKeysWithoutCharts = [
    CHART_KEYS.unique_customers,
    CHART_KEYS.unique_devices,
    CHART_KEYS.sessions,
    CHART_KEYS.average_duration,

    CHART_KEYS.average_playback_session,
    CHART_KEYS.average_playback_try,
    CHART_KEYS.average_session_duration
];
export const WidgetKeysWithCharts = [
    CHART_KEYS.device_category,
    CHART_KEYS.device_type,
    CHART_KEYS.device_manufacturer,
    CHART_KEYS.device_model,
    CHART_KEYS.os,
    CHART_KEYS.client_version,
    CHART_KEYS.os_version,
    CHART_KEYS.geo_country,
    CHART_KEYS.movie_title
] as const;
export const labelData = (data: PlatformAnalyticsData) => {
    const labeledData = data.map((data) => ({ ...data, label: data.label || data.id }));
    return labeledData;
};

export const calcSumOfDataEntries = (data: PlatformAnalyticsData) => data.reduce((sum, { value }) => sum + value, 0);

// returns a number as string with '.'-s
export const formatNumber = (num: number) => {
    const formatter = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    return formatter.format(num);
};

//returns a 'mm:ss' string from a number of seconds
export const formatSeconds = (seconds: number) =>
    `${String(Math.floor(seconds / 60)).padStart(2, '0')}:${String(seconds % 60).padStart(2, '0')}`;

// calculate change percent and typeof indicator to show

export type AnalyticsChangeIndicator = 'POSITIVE' | 'NEGATIVE' | 'NO-CHANGE';

export const analyticsChangeIconSwitcher = (changeIndicator?: AnalyticsChangeIndicator) => {
    switch (changeIndicator) {
        case 'NEGATIVE':
            return icons.analyticsChangeNegative;
        case 'NO-CHANGE':
            return icons.analyticsNoChange;
        case 'POSITIVE':
            return icons.analyticsChangePositive;
        default:
            return '';
    }
};
export const calculateChangePercentAndIndicator = (
    value: number,
    comparedValue: number
): { percentChange: number; changeIndicator: AnalyticsChangeIndicator } => {
    const percentChange = Math.abs(Math.floor(((value - comparedValue) / comparedValue) * 100));
    return {
        percentChange: percentChange,
        changeIndicator: percentChange === 0 ? 'NO-CHANGE' : comparedValue < value ? 'POSITIVE' : 'NEGATIVE'
    };
};

export const calculatePercentageAndIndicator = (
    totalDevices: number,
    value: number,
    comparedValue: number
): { percentChange: number; changeIndicator: AnalyticsChangeIndicator } => {
    const percentage = Math.round((value / totalDevices) * 100);
    return {
        percentChange: percentage,
        changeIndicator: percentage === 0 ? 'NO-CHANGE' : comparedValue < value ? 'POSITIVE' : 'NEGATIVE'
    };
};

export const createPercentChangeLabel = (percentChange: number) => {
    return percentChange === Infinity ? 'NEW' : `${percentChange}%`;
};

export const calculateComparedTimeframeLabel = (timeframe: string, customDate?: { startDate: number; endDate: number }) => {
    if (timeframe in COMPARED_TIMEFRAME_LABEL_MAPPING) {
        return `in the ${COMPARED_TIMEFRAME_LABEL_MAPPING[timeframe]}`;
    }
    if (timeframe === 'custom_placeholder' && customDate) {
        const timeframeDifference = customDate.endDate - customDate.startDate;

        const comparedFromDate = customDate.startDate - timeframeDifference;
        const comparedToDate = customDate.endDate - timeframeDifference;

        return `between ${createLabelFromStartEndDate(comparedFromDate, comparedToDate)}`;
    }
    return 'Unknown timeframe';
};
export const createLabelFromStartEndDate = (startDate: number = 0, endDate: number = 0) => {
    return `${moment.unix(startDate).format('DD MMM YYYY')} - ${moment.unix(endDate).format('DD MMM YYYY')}`;
};
