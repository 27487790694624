import { TooltipDivider } from '../../../Modules/Modules.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../../style';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../Tooltips';
import React, { useState } from 'react';
import { ObjectType, ObjectTypes } from '../../../../types/Object';
import { BlueObjectName, ObjectName, ObjectNameWrapper, SVGWrapper, TooltipLink, TooltipTrigger } from './ObjectNameTooltip.css';
import { buildPathWithProjectId, PageRoutes } from '../../../../types/RouteTypes';
import { ActiveItemState } from '../../../../redux/slices/activeItemSlice';
import { useAppSelector } from '../../../../hooks/redux';
import { ObjectNameTooltipContentHolder } from '../Tooltips.css';
import { EMPTY_WORD_STRING } from '../../../../utils/Globals';

interface IProps {
    id: string;
    name: string;
    type: Exclude<ObjectType, 'languages'>;
}

const ObjectNameTooltip: React.FC<IProps> = ({ id, name, type }) => {
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const [isOpen, setIsOpen] = useState(false);

    const objectConfig = {
        [ObjectTypes.PAGES]: {
            icon: icons.pagesIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.PAGES)}/${id}`
        },
        [ObjectTypes.MODULES]: {
            icon: icons.modulesIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.MODULES)}?id=${id}`
        },
        [ObjectTypes.ITEMS]: {
            icon: icons.itemsIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.ITEMS)}?id=${id}`
        },
        [ObjectTypes.MENUS]: {
            icon: icons.menusIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.MENUS)}?id=${id}`
        },
        [ObjectTypes.AUDIENCES]: {
            icon: icons.conditionsIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.AUDIENCES)}?id=${id}`
        },
        [ObjectTypes.TARGET_GROUPS]: {
            icon: icons.usersIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_GROUPS)}/${id}`
        },
        [ObjectTypes.CONDITIONS]: {
            icon: icons.displayConditionsIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_CONDITIONS)}?id=${id}`
        },
        [ObjectTypes.SETTINGS]: {
            icon: icons.settingsIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.SETTINGS)}?id=${id}`
        },
        [ObjectTypes.PAGE_STYLES]: {
            icon: icons.styleAndBrandingIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.PAGE_STYLES)}?id=${id}`
        },
        [ObjectTypes.DYNAMIC_SOURCES]: {
            icon: icons.sourcesIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.SOURCES)}?id=${id}`
        },
        [ObjectTypes.FEATURE_FLAGS]: {
            icon: icons.featureFlagIcon,
            to: `${buildPathWithProjectId(activeProjectId, PageRoutes.FEATURE_FLAGS)}?id=${id}`
        }
    };

    const { icon, to } = objectConfig[type];

    const tooltipValue = (
        <ObjectNameTooltipContentHolder>
            <SVGWrapper>
                <SVGInline src={icon} />
            </SVGWrapper>
            <ObjectName>{name || EMPTY_WORD_STRING}</ObjectName>
            <TooltipDivider />
            <TooltipLink to={to} onClick={(e) => e.stopPropagation()} target={'_blank'}>
                Edit
            </TooltipLink>
        </ObjectNameTooltipContentHolder>
    );

    return (
        <ObjectNameWrapper onMouseLeave={() => setIsOpen(false)}>
            <BlueObjectName className={'object_name_with_tooltip'} onMouseEnter={() => setIsOpen(true)}>
                {name || EMPTY_WORD_STRING}
            </BlueObjectName>
            {renderTooltip(<TooltipTrigger />, tooltipTypes.HTML, tooltipValue, tooltipPositions.TOP, false, true, false, isOpen)}
        </ObjectNameWrapper>
    );
};

export default ObjectNameTooltip;
