export enum PageRoutes {
    LOGIN = '/login',
    PROJECTS = '/projects',
    PROJECT = '/projects/:id',
    TENANTS = '/tenants',
    USERS_GROUPS = '/users_and_groups',
    DASHBOARD = '/dashboard',
    AGGREGATE = '/aggregate',
    ANALYZE = '/analyze',
    MONETIZE = '/monetize',
    AD_RULES = '/ad_rules',
    PERSONALIZE = '/personalize',
    PAGES = '/pages',
    NEW_PAGE = '/pages/new',
    PAGE = '/pages/:page_id',
    MODULES = '/modules',
    ITEMS = '/items',
    MENUS = '/menus',
    PAGE_STYLES = '/page_styles',
    SETTINGS = '/settings',
    FEATURE_FLAGS = '/feature_flags',
    AUDIENCES = '/audiences',
    SOURCES = '/sources',
    LANGUAGES = '/languages',
    LANGUAGE_TRANSLATIONS = '/languages/:language_id/translations',
    TARGET_CONDITIONS = '/conditions',
    FILE_MANAGER = '/file_manager',
    OPTIMIZE = '/optimize',
    TARGET_GROUPS = '/target_groups',
    TARGET_GROUP = '/target_groups/:group_id',
    SEARCH = '/search',
    UNPUBLISHED_CHANGES = '/unpublished_changes',
    NO_ACCESS = '/noaccess',
    SUPERADMIN_GROUPS = '/superadmin/groups',
    SUPERADMIN_TENANTS = '/superadmin/tenants',
    SUPERADMIN_PROJECTS = '/superadmin/projects',
    SUPERADMIN_BRANDING = '/superadmin/branding',
    SUPERADMIN_AUDIENCES = '/superadmin/groups/:group_id/audiences',
    SUPERADMIN_CONFIG = '/superadmin/config/:tenant_id',
    SUPERADMIN_MODULES = '/superadmin/modules',
    SUPERADMIN_ITEMS = '/superadmin/items',
    SUPERADMIN_PAGE_STYLES = '/superadmin/pageStyles',
    SUPERADMIN_SOURCES = '/superadmin/sources',
    SUPERADMIN_PAGES = '/superadmin/pages',
    SUPERADMIN_PAGE = '/superadmin/pages/:page_id'
}

export const superadminRoutes = [
    PageRoutes.SUPERADMIN_GROUPS,
    PageRoutes.SUPERADMIN_TENANTS,
    PageRoutes.SUPERADMIN_PROJECTS,
    PageRoutes.SUPERADMIN_BRANDING,
    PageRoutes.SUPERADMIN_AUDIENCES,
    PageRoutes.SUPERADMIN_CONFIG,
    PageRoutes.SUPERADMIN_MODULES,
    PageRoutes.SUPERADMIN_ITEMS,
    PageRoutes.SUPERADMIN_PAGE_STYLES,
    PageRoutes.SUPERADMIN_PAGES,
    PageRoutes.SUPERADMIN_PAGE,
    PageRoutes.SUPERADMIN_MODULES,
    PageRoutes.SUPERADMIN_SOURCES
];

export const noProjectIdRoutes = [PageRoutes.LOGIN, PageRoutes.PROJECTS, PageRoutes.TENANTS, PageRoutes.USERS_GROUPS, ...superadminRoutes];

export const buildPathWithProjectId = (id: string = '', route: string) => {
    const base = `/projects/${id}`;
    return `${base}${route}`;
};

export const changeProjectIdPath = (path: string, currentId: string) => {
    const urlParts = path.split('/');
    urlParts.splice(2, 1, currentId);
    return urlParts.join('/');
};
