import styled from 'styled-components';
import SVGInline from 'react-inlinesvg';
import getTheme, { fontSizesCSS, zIndexesCSS } from '../themes/theme';
import { LoaderContainer } from '../../components/common/Loader/Loader.css';
import { TranslationTooltipIconContainer } from '../../components/common/TranslationTooltip/TranslationTooltip.css';
import Button from '../../components/Buttons/Button/Button';
import { ButtonWrapper } from '../../components/Buttons/Button/Button.css';

const theme: any = getTheme();

export enum SCREEN_SIZES {
    DESKTOP_L = 1920,
    DESKTOP_S = 1440,
    TABLET = 960,
    MOBILE_L = 720,
    MOBILE_S = 600
}

export const devices = {
    mobileS: `@media (max-width: ${SCREEN_SIZES.MOBILE_S}px)`,
    mobileL: `@media (max-width: ${SCREEN_SIZES.MOBILE_L}px)`,
    tablet: `@media (max-width: ${SCREEN_SIZES.TABLET}px)`,
    desktopS: `@media (max-width: ${SCREEN_SIZES.DESKTOP_S}px)`,
    desktopL: `@media (max-width: ${SCREEN_SIZES.DESKTOP_L}px)`
};

export const StyledSVGInline = styled(SVGInline)`
    width: 24px;
    height: 24px;
    z-index: ${zIndexesCSS.zIndexItem};
    padding: 0 10px;
    cursor: pointer;
`;

export const SearchSVGInline = styled(StyledSVGInline)`
    width: 20px;
    height: 20px;
    padding: 0;
    color: ${theme.palette.text.main};
    cursor: pointer;
`;

export const SidebarSVGInline = styled(StyledSVGInline)`
    width: 18px;
    height: 18px;
    opacity: 0.4;
`;

export const ProfileSVGInline = styled(StyledSVGInline)`
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 8px;
    vertical-align: super;
`;

export const LoadingWrapper = styled.span`
    padding-top: 8px;
`;

export const ScreenActions = styled.div<{ $maxHeight?: boolean; $loading?: boolean }>`
    min-height: ${(props) => (props.$maxHeight ? '100px' : '32px')};
    width: 100%;
    padding-bottom: 36px;
    color: ${theme.palette.text.main};
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    ${LoadingWrapper} {
        ${(props) => props.$loading && `pointer-events: none; opacity: 0.7;`}}
    }
`;

export const MainContentWrapper = styled.div<{ noSidebar?: boolean; noTransition?: boolean; noHiddenOverflow?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 36px 36px 100px 36px;
    ${(props) => (!props.noSidebar ? 'width:  calc(100% - 72px)' : 'width: 100%')};
    transition: ${(props) => (props.noTransition ? '' : 'width 150ms ease-in-out')};

    ${devices.desktopS} {
        padding: ${(props) => (props.noSidebar ? '36px 36px 100px 36px' : '36px 36px 100px 108px')};
        width: 100%;
    }
    overflow: ${(props) => (props.noHiddenOverflow ? 'unset' : 'hidden')}; ;
`;

export const PageValuesContainer = styled.div``;
export const PageButtonsWrapper = styled.div`
    z-index: 30;
    background-color: ${theme.palette.background.main};
    display: flex;
    width: 100%;
    height: 84px;
    justify-content: flex-end;
    align-items: center;
    gap: 0.8%;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 36px;
    box-sizing: border-box;
    ${ButtonWrapper} {
        width: 150px;
        height: 36px;
        margin: 0;
        padding: 0;
    }
    a {
        margin: 0;
    }
`;

export const ApplicationWrapper = styled.div<{ $loading?: boolean }>`
    min-width: 100%;
    min-height: 100vh;
    display: flex;
    background: ${theme.palette.background.default};

    & > *:not(${MainContentWrapper}):not(${PageButtonsWrapper}):not(${PageValuesContainer}),
    ${PageButtonsWrapper} > *,
    ${PageValuesContainer} > * {
        ${({ $loading }) => $loading && 'opacity: 0.7; pointer-events: none'};
    }

    ${MainContentWrapper} {
        & > *:not(${LoaderContainer}):not(${PageButtonsWrapper}):not(${PageValuesContainer}),
        ${PageButtonsWrapper} > *,
        ${PageValuesContainer} > * {
            ${({ $loading }) => $loading && 'opacity: 0.7; pointer-events: none'};
        }
    }
`;
export const StyledImage = styled.img`
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-position: center;
    object-fit: cover;
`;

export const DropdownOptionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    ${TranslationTooltipIconContainer} {
        svg {
            width: 14px;
            height: 14px;
        }
    }
`;

export const IconPreviewImage = styled.img<{ $invert?: boolean }>`
    object-fit: cover;
    object-position: center;
    // use invert to fill SVGs with #333333 color
    ${({ $invert }) => $invert && `filter: invert(16%) sepia(0%) saturate(1462%) hue-rotate(141deg) brightness(94%) contrast(85%);`}
`;

export const IconsSelectOptionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    span {
        padding-left: 10px;
    }
    ${IconPreviewImage} {
        padding-left: 10px;
        width: 24px;
        height: 24px;
    }
`;

export const NoResourcesContainer = styled.div`
    display: flex;
    gap: 24px;
    ${devices.mobileL} {
        justify-content: center;
    }
`;

export const TruncatedText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
`;

export const LabelContainer = styled.div`
    align-items: center;
    display: flex;
    width: 100%;
    gap: 4px;
`;

export const ObjectNameLabel = styled(TruncatedText)<{ $isNotClickable?: boolean; $isBlack?: boolean }>`
    display: flex;
    padding: 5px 8px;
    border-radius: 4px;
    width: fit-content;
    align-items: center;
    box-sizing: border-box;
    ${fontSizesCSS.Caption3};

    cursor: ${(props) => (props.$isNotClickable ? 'default' : 'pointer')};

    ${({ $isBlack }) =>
        $isBlack
            ? ` border: 1px solid ${theme.palette.background.border}; color: ${theme.palette.black.main}; background-color: ${theme.palette.background.actions};`
            : ` border: 1px solid ${theme.palette.background.pressed}; color: ${theme.palette.primary.main}; background-color: ${theme.palette.background.main};`}

    // TRANSLATION ICON SIZE
    svg {
        margin-left: 4px;
        height: 14px;
        width: 14px;
    }
`;

export const ShowMoreButton = styled.div<{ $isBlack?: boolean }>`
    cursor: pointer;
    padding: 5px 7px;
    border-radius: 4px;
    ${fontSizesCSS.Caption3};
    background-color: ${theme.palette.background.main};

    ${({ $isBlack }) =>
        $isBlack
            ? ` border: 1px solid ${theme.palette.background.border}; color: ${theme.palette.black.main}; background-color: ${theme.palette.background.actions};`
            : ` border: 1px solid ${theme.palette.background.pressed}; color: ${theme.palette.primary.main}; background-color: ${theme.palette.background.main};`}
`;

export const DropdownLabel = styled.div`
    height: 24px;
    width: 100%;
    background: ${theme.palette.background.pressed};
    span {
        ${fontSizesCSS.Caption4};
        font-weight: 700;
    }
    padding-left: 8px;
`;

export const DeepLinkSpan = styled.span`
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: ${theme.palette.primary.main};
    &:hover {
        cursor: pointer;
    }
`;

export const PublishedTextSpan = styled.span`
    ${fontSizesCSS.Title3}
    font-weight: normal;
`;

// we use this when the datepicker needs to behave like a dialog
// so the backdrop is added on top of the page when it is open
export const DatepickerBackdrop = styled.div`
    background: ${theme.palette.background.backdrop};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1300;
`;

// Placed in published object error message

export const PlacedInPublishedWarningMessageWrapper = styled.div`
    margin: 4px 0;
    padding: 8px;
    border: 2px solid ${theme.palette.error.main};
    font-weight: bold;
    border-radius: 4px;
    color: ${theme.palette.error.main};
`;

// ------

export const ObjectListWrapper = styled.div`
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;

    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
        margin-left: 4px;
        margin-top: 24px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${theme.palette.background.border};
        border-radius: 8px;
    }
`;

export const ErrorObjectWrapper = styled.div`
    cursor: pointer;
    color: ${theme.palette.primary.main};
`;

export const MultipleErrorObjectWrapper = styled(ErrorObjectWrapper)`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const ObjectListItem = styled.div`
    ${fontSizesCSS.Caption1};
`;
export const ObjectListHeader = styled.div`
    ${fontSizesCSS.Body2};
`;
export const PageActionButton = styled(Button)`
    width: 158px;
    height: 36px;
    margin: 0 12px;
    border-radius: 4px;
    padding: 6px 16px;
`;
export const PageActionsWrapper = styled.div`
    display: flex;
    border-radius: 4px;
    background-color: ${theme.palette.background.actions};
    margin-top: 24px;
    padding: 24px 0;
    align-items: center;
    justify-content: center;
`;

export const LastModifiedTableCell = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`;
