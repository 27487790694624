import React, { FC, useEffect, useRef, useState } from 'react';
import GenericDialog, { DialogTextField, DialogTypes } from '../Dialog/GenericDialog';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    CalendarContainer,
    DateContainer,
    DatePickerDialogContainer,
    DateTextSection,
    DateTimeActionsWrapper,
    DateTimeCloseIcon,
    DateTimeHeaderContainer,
    DateTimeTitle,
    MonthHeaderContainer
} from './DatePicker.css';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import { IconButton, InputAdornment } from '@material-ui/core';
import { textFieldStyle } from '../TimePicker/TimePicker';
import useScreenSize from '../../../hooks/useScreenSize';
import { HelpIconWrapper } from '../Dialog/GenericDialog.css';
import HelpIcon, { CIRCLE_SLUGS } from '../HelpIcon/HelpIcon';
import { DatepickerBackdrop } from '../../../style/styled-components/reusable.css';

export type DatePickerProps = {
    isOpen: boolean;
    sDate?: number;
    eDate?: number;
    withCancel?: boolean;
    saveDate: (arg: any) => void;
    onCancel?: () => void;
    onClose?: () => void;
    toggleOpenDialog: (arg: boolean) => void;
    index?: number;
    filterDate?: (date: any) => boolean;
    customLabel?: string;
    customTooltip?: string;
    customHeaderText?: string;
    noTooltip?: boolean;
    renderCustomInput?: () => JSX.Element | null;
    isRange?: boolean;
    showOnlyYears?: boolean;
    optional?: boolean;
    autoFocus?: boolean;
    withoutHeader?: boolean;
};

export const DatePickerComponent: FC<DatePickerProps> = ({
    isOpen,
    sDate,
    eDate,
    withCancel,
    saveDate,
    onCancel,
    onClose,
    toggleOpenDialog,
    index,
    filterDate,
    customLabel,
    customTooltip,
    noTooltip,
    customHeaderText,
    renderCustomInput,
    isRange,
    showOnlyYears,
    optional,
    autoFocus,
    withoutHeader
}) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const datepickerRef = useRef<any>(null);

    const { isSmallMobile, isMobile } = useScreenSize();
    const yearIncrement = 20;

    const cancelAdornment = () => (
        <InputAdornment position="end">
            <IconButton
                onClick={(e: any) => {
                    e.stopPropagation();
                    onCancel && onCancel();
                }}
            >
                <SVGInline src={icons.closeIcon} />
            </IconButton>
        </InputAdornment>
    );

    const dateAdornment = () => (
        <InputAdornment position="end">
            <IconButton>
                <SVGInline src={icons.calendarIcon2} />
            </IconButton>
        </InputAdornment>
    );

    const renderTextField = () => {
        return (
            <DateTextSection>
                <DialogTextField
                    value={`${startDate?.toLocaleDateString()?.replace(/\//g, ' ') || ''}`}
                    onChange={() => {}}
                    style={textFieldStyle}
                    isDisabled
                    whiteWhileDisabled
                />
                <SVGInline src={icons.arrowRightFullIcon} />
                <DialogTextField
                    value={`${endDate?.toLocaleDateString()?.replace(/\//g, ' ') || ''} `}
                    onChange={() => {}}
                    style={textFieldStyle}
                    isDisabled
                    whiteWhileDisabled
                />
            </DateTextSection>
        );
    };

    const renderHeader = () => {
        if (withoutHeader) return null;
        return (
            <DateTimeHeaderContainer isDate>
                <DateTimeTitle>{customHeaderText || 'Date Selection'}</DateTimeTitle>
                <DateTimeActionsWrapper>
                    <HelpIconWrapper>
                        <HelpIcon />
                    </HelpIconWrapper>
                    <DateTimeCloseIcon
                        onClick={() => {
                            toggleOpenDialog(false);
                            onClose?.();
                        }}
                    >
                        <SVGInline src={icons.closeIcon} />
                    </DateTimeCloseIcon>
                </DateTimeActionsWrapper>
            </DateTimeHeaderContainer>
        );
    };

    const renderMonthHeader = (month: Date, decreaseMonth: () => void, increaseMonth: () => void) => {
        return (
            <MonthHeaderContainer>
                <div className={'react-datepicker__navigation react-datepicker__navigation--previous'} onClick={decreaseMonth}>
                    <span className={'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'}>{'<'}</span>
                </div>
                <span className="react-datepicker__current-month">
                    {month?.toLocaleString('en-US', {
                        month: 'long',
                        year: 'numeric'
                    })}
                </span>
                <div className={'react-datepicker__navigation react-datepicker__navigation--next'} onClick={increaseMonth}>
                    <span className={'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'}>{'>'}</span>
                </div>
            </MonthHeaderContainer>
        );
    };

    const renderYearHeader = (decreaseYear: () => void, increaseYear: () => void, date: Date) => {
        const selectedYear = date.getFullYear();
        const currentYear = new Date().getFullYear();

        const startYear = 1941;
        const yearIntervals: string[] = [];
        for (let i = 1941; i <= currentYear; i += yearIncrement) {
            yearIntervals.push(`${i}-${i + yearIncrement - 1}`);
        }

        let selectedInterval = yearIntervals[0];
        for (let interval of yearIntervals) {
            const limits = interval.split('-');
            if (selectedYear >= parseInt(limits[0]) && selectedYear <= parseInt(limits[1])) {
                selectedInterval = interval;
                break;
            }
        }

        const endYear = parseInt(yearIntervals[yearIntervals.length - 1].split('-')[1]);

        return (
            <MonthHeaderContainer>
                <div
                    className={'react-datepicker__navigation react-datepicker__navigation--previous'}
                    onClick={selectedYear - yearIncrement < startYear ? () => null : decreaseYear}
                >
                    <span className={'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'}>{'<'}</span>
                </div>
                <span className="react-datepicker__current-month">{selectedInterval}</span>
                <div
                    className={'react-datepicker__navigation react-datepicker__navigation--next'}
                    onClick={selectedYear + yearIncrement > endYear ? () => null : increaseYear}
                >
                    <span className={'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'}>{'>'}</span>
                </div>
            </MonthHeaderContainer>
        );
    };

    const renderDefaultInputField = () => {
        return (
            <DialogTextField
                value={`${startDate} - ${endDate}`}
                isDisabled
                whiteWhileDisabled
                onChange={() => {}}
                InputProps={{ endAdornment: withCancel ? cancelAdornment() : dateAdornment() }}
                label={customLabel || `Date interval ${typeof index === 'number' ? index + 1 : ''}`}
                optional={optional || (typeof index === 'number' && index > 0)}
                toolTipText={noTooltip ? undefined : customTooltip || 'Only one interval is mandatory'}
            />
        );
    };

    const handleClose = () => {
        toggleOpenDialog(false);
        setStartDate(null);
        setEndDate(null);
    };

    const handleDateChange = (date: any) => {
        if (isRange) {
            const [start, end] = date;

            setStartDate(start);
            setEndDate(end);
            if (start && end) {
                // endDate should include the whole day
                saveDate({
                    startDate: Math.floor(start.getTime() / 1000),
                    endDate: Math.floor((end.getTime() + 24 * 60 * 60 * 999) / 1000)
                });
                handleClose();
            }
        } else {
            setStartDate(date);
            saveDate(Math.floor(date.getTime() / 1000));
        }
    };

    useEffect(() => {
        if (sDate) {
            setStartDate(new Date(sDate * 1000));
        } else {
            setStartDate(null);
        }

        if (eDate) {
            setEndDate(new Date(eDate * 1000));
        } else {
            setEndDate(null);
        }
    }, [sDate, eDate]);

    const DialogContainer: FC = ({ children }) => {
        return <CalendarContainer oneMonth={isSmallMobile || !isRange}>{children}</CalendarContainer>;
    };

    return (
        <DateContainer
            onKeyDownCapture={(event) => {
                if (!isOpen) return;
                event.stopPropagation();
                if (event.key === 'Escape') {
                    toggleOpenDialog(false);
                }
            }}
            tabIndex={-1}
        >
            <DatePicker
                dateFormat={showOnlyYears ? 'yyyy' : undefined}
                showYearPicker={showOnlyYears}
                ref={datepickerRef}
                open={isOpen}
                autoFocus={autoFocus}
                popperPlacement={'top-start'}
                selected={startDate}
                onChange={(date) => {
                    handleDateChange(date);
                }}
                calendarContainer={DialogContainer}
                startDate={startDate}
                endDate={endDate}
                yearItemNumber={showOnlyYears ? yearIncrement : undefined}
                selectsRange={isRange}
                monthsShown={isSmallMobile || !isRange ? 1 : 2}
                popperModifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: ({ reference }) => {
                                const { x, y } = reference;
                                const offsetX = isSmallMobile ? x / 2 : isMobile ? -x * 1.5 : -x;

                                return [offsetX - 32, y + 102];
                            }
                        }
                    },
                    {
                        name: 'flip',
                        enabled: false //Prevents flipping the popper to another side
                    }
                ]}
                filterDate={filterDate ? (date) => filterDate(date) : undefined}
                renderCustomHeader={({ increaseMonth, decreaseMonth, monthDate, decreaseYear, increaseYear, date }) =>
                    showOnlyYears
                        ? renderYearHeader(decreaseYear, increaseYear, date)
                        : renderMonthHeader(monthDate, decreaseMonth, increaseMonth)
                }
                onInputClick={() => toggleOpenDialog(true)}
                customInput={renderCustomInput ? renderCustomInput() : renderDefaultInputField()}
            >
                {renderHeader()}
                {isRange && renderTextField()}
            </DatePicker>
        </DateContainer>
    );
};

export const DatePickerDialog: FC<DatePickerProps> = ({ isOpen, isRange, onClose, ...rest }) => {
    const { isSmallMobile } = useScreenSize();
    if (!isOpen) return null;
    return (
        <>
            <GenericDialog
                title={'Date selection'}
                type={isRange && !isSmallMobile ? DialogTypes.DatePickerRange : DialogTypes.DatePicker}
                circlesSlugOptions={{ default: CIRCLE_SLUGS.conditions }}
                onClose={onClose}
            >
                {/*this div serves for making the datepicker appear in the center*/}
                <DatePickerDialogContainer>
                    <DatePickerComponent isOpen={isOpen} isRange={isRange} {...rest} renderCustomInput={() => <></>} />
                </DatePickerDialogContainer>
            </GenericDialog>
        </>
    );
};
