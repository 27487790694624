import React, { ChangeEvent, useEffect, useState } from 'react';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import {
    ApplicationWrapper,
    MainContentWrapper,
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { Loader } from '../common/Loader/Loader';
import PageActions from '../common/PageActions/PageActions';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';

import {
    AbTestingSelectWrapper,
    DialogTextFieldWrapper,
    GroupedSettingSubsection,
    InputAdornmentText,
    LastModified,
    SettingEditContainer,
    SettingsConditionsView,
    SettingsSubsection,
    SettingsSubsectionRow,
    SettingsSubsectionRowName,
    SettingsSubsectionsHolder,
    SettingSubsectionName,
    SettingTitleContainer,
    SubsectionInputAdornment
} from './Settings.css';
import { DialogCheckbox, DialogDropdownSingle, DialogTextField } from '../common/Dialog/GenericDialog';
import SVGInline from 'react-inlinesvg';
import icons from '../../assets/images/icons';
import {
    DefaultSettingValues,
    groupedLoginSubsections,
    loginSubsectionKeys,
    Setting,
    settingKeysWithTranslations,
    SETTINGS_TOOLTIPS,
    settingsDropdownOptions,
    SettingSectionType,
    settingsSections,
    SettingsUMTypes,
    SettingsValues,
    SUBSECTION_NAMES
} from '../../types/Setting';
import _ from 'lodash';
import { TableRow, Tooltip } from '@material-ui/core';
import {
    abortPublishSetting,
    createSetting,
    deleteSetting,
    fetchSettings,
    fetchSettingsTitles,
    fetchSettingType,
    publishSetting,
    settingsState,
    unsetSettings,
    updateSetting
} from '../../redux/slices/settingsSlice';
import { setUserPermissions } from '../../redux/slices/permissionsSlice';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import { generateDateStringForTables } from '../../utils/fnDate';
import { DIALOG_NAMES, dialogConfirm, handleCheckBeforeSave, ToastAlert } from '../../utils/fnDialogs';
import { UnsavedSpan } from '../common/DashboardTitle/DashboardTitle.css';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import { useLocation, useNavigate } from 'react-router-dom';
import useBlockNavigation from '../../hooks/useBlockNavigation';
import useScreenSize from '../../hooks/useScreenSize';
import { TargetConditionsSelect } from '../common/Select/TargetConditionsSelect';
import { API_ERROR_CODES, EMPTY_WORD_STRING } from '../../utils/Globals';
import { DisplayConditionsState, fetchDisplayConditions } from '../../redux/slices/displayConditionsSlice';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderABIcon, renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../utils/fnLockingSystem';
import { audiencesState, fetchAudiences } from '../../redux/slices/audienceSlice';
import { ObjectActions } from '../common/Actions/Actions';
import GenericTable, { HeaderTableCell, SortableHeaderTableCell, tableActions } from '../common/Table/Table';
import { RemoveModuleWrapper } from '../Modules/Modules.css';
import { getSearchParam } from '../../utils/fnUrl';
import { PUBLISHED_STATUS } from '../../utils/fnPublish';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { fetchTargetGroups, targetGroupsState } from '../../redux/slices/targetGroupsSlice';
import Labels from '../common/Labels/Labels';
import { withScroll } from '../../HOCs/ScrollableWrapper/ScrollableWrapper';
import { SettingsTableSizes } from '../../types/TableSizes';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    calculateOrderByFromSortConfig,
    DEFAULT_SORT_CONFIG,
    ISortConfig
} from '../../utils/fnSort';
import { TableRowWrapper, WidthTableCell } from '../common/Table/Table.css';
import { ObjectTypes } from '../../types/Object';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { ResourceCard } from '../Cards/ResourceCard/ResourceCard';
import { resourceCardImages } from '../../assets/images/resourceCards';
import { openDocumentation } from '../../utils/parsers';
import circleSlugs from '../../utils/circle_slugs.json';
import { ABTestingGroupSelect } from '../common/Select/ABTestingGroupSelect';
import { SearchBarWrapper } from '../PaginationWrapper/PaginationWrapper.css';
import { FancyFilter, searchTermUnsetValue } from '../common/Select/FancyFilter';
import { ObjectFilter } from '../../utils/fnFilter';
import useTranslation from '../../hooks/useTranslation';
import { renderUnsavedAlertBeforeAction, ResetCallbackProps } from '../PaginationWrapper/PaginationWrapper';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';

export const ScrollableSettingsContainer = withScroll(TableRowWrapper);

const Settings = () => {
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    // SETTINGS
    const {
        settings: storeSettings,
        settingType,
        settingsTitles,
        error,
        loading
    }: settingsState = useAppSelector((state) => state.settings);
    const [settings, setSettings] = useState<Setting[]>([]);

    // Hold the Setting object which is being worked on
    const [settingToEdit, setSettingToEdit] = useState<any | undefined>(undefined);
    // Tracks whether the top values can be edited or not
    const [isEditing, setIsEditing] = useState(false);
    const [creatingSetting, setCreatingSetting] = useState(false);
    // used when settings is opened from a group
    const [settingId, setSettingId] = useState<string>('');
    const [showUnsaved, setShowUnsaved] = useState(false);

    // SETTING SUBSECTIONS
    const [expandedSubsectionKeys, setExpandedSubsectionKeys] = useState<{ [key: string]: boolean } | null>(null);
    const [sectionsToShow, setSectionsToShow] = useState<SettingSectionType[]>([]);
    const [defaultSetting, setDefaultSetting] = useState<SettingsValues>({});

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();
    const { translate } = useTranslation();
    const { isDesktop, isMobile, isLargeDesktop } = useScreenSize();
    const { loading: conditionsLoading }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { audiences: storeAudiences }: audiencesState = useAppSelector((state) => state.audiences);
    const { targetGroups }: targetGroupsState = useAppSelector((state) => state.targetGroups);

    // SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.lastModified);
    const [activeObjectFilter, setActiveObjectFilter] = useState<ObjectFilter | undefined>(undefined);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    const unsavedDependencyArray = [creatingSetting, settingToEdit];

    // Blocking navigation when there are unsaved changes
    useBlockNavigation(showUnsaved, () => renderAlertUnsavedChanges(), unsavedDependencyArray);

    //Locking
    const { lock, unlock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.SETTINGS);

    const resetCallback = (keep?: ResetCallbackProps) => {
        !keep?.searchTerm && setSearchTerm('');
        !keep?.sortConfig && setActiveSortingKey(DEFAULT_SORT_CONFIG.field as AcceptedSortField);
        !keep?.sortConfig && setSortConfig(DEFAULT_SORT_CONFIG);
        !keep?.filterObject && setActiveObjectFilter(undefined);
        if (!keep?.visualEditor) {
            settingToEdit?._id && unlock(settingToEdit?._id);
            setExpandedSubsectionKeys(null);
            setSettingToEdit(undefined);
            setSettingId('');
        }
    };

    const loadSettings = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        setSettings([]);
        if (storeSettings.length && getSearchParam('id')) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.SETTINGS));
        }
        // if redirected, reset the search term and filter after any action
        const isRedirected = !!activeObjectFilter?._id;
        if (isRedirected) {
            setActiveObjectFilter(undefined);
            setSearchTerm('');
        } else {
            // there might be a searchTerm in the fancy filter but a search was not conducted, so we need to clear the searchTerm inside the fancy filter
            // to do that we assign the searchTerm a special value which will be used in the fancy filter to clear both search terms
            const settingId = location?.state?.settingId || getSearchParam('id');
            if (!settingId && !searchTerm) setSearchTerm(searchTermUnsetValue);
        }
        return await dispatch(
            fetchSettings({ addPermissions, projectId, orderBy, ...(!isRedirected && { searchTerm }), ...(!isRedirected && { filter }) })
        ).unwrap();
    };

    const loadSettingType = async (tenantId: string) => {
        return await dispatch(fetchSettingType(tenantId));
    };

    const loadSettingsTitles = async () => {
        return await dispatch(fetchSettingsTitles()).unwrap();
    };

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId }));
    };
    const loadConditions = async () => {
        return await dispatch(fetchDisplayConditions({ addPermissions: false, projectId: activeProjectId }));
    };
    const loadTargetGroups = () => {
        return dispatch(fetchTargetGroups({ projectId: activeProjectId }));
    };

    const saveSetting = async (newSetting: Setting, reload: boolean = true, addObjectToGroup?: boolean, publishing?: boolean) => {
        const response = await dispatch(createSetting({ setting: newSetting, addObjectToGroup })).unwrap();
        if (response.id) {
            ToastAlert(
                'success',
                '',
                '',
                undefined,
                publishing
                    ? newSetting.publishAt
                        ? DIALOG_NAMES.SETTINGS_SCHEDULED_PUBLISH_AND_SAVED
                        : DIALOG_NAMES.SETTINGS_PUBLISHED_AND_SAVED
                    : DIALOG_NAMES.SETTINGS_CREATED
            );
            loadTargetGroups();
            resetCallback();
            reload && loadSettings(false, activeProjectId, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG));
        }
        return response.id;
    };

    const modifySetting = async (setting: Setting, reload: boolean = true, addObjectToGroup?: boolean, publishing?: boolean) => {
        const response = await dispatch(updateSetting({ setting, shouldUnlockAfterSave: true, addObjectToGroup })).unwrap();
        const showScheduledPublishAlert = setting.publishAt && setting.publishAt !== settingToEdit?.publishAt;
        if (response.id) {
            showScheduledPublishAlert
                ? ToastAlert('success', '', '', icons.scheduledPublishWhiteIcon, DIALOG_NAMES.SETTINGS_SCHEDULED_PUBLISH_AND_SAVED)
                : ToastAlert(
                      'success',
                      '',
                      '',
                      undefined,
                      publishing ? DIALOG_NAMES.SETTINGS_PUBLISHED_AND_SAVED : DIALOG_NAMES.SETTINGS_UPDATED
                  );
            loadTargetGroups();
            if (reload) {
                resetCallback({ visualEditor: true, filterObject: true, searchTerm: true });
                loadSettings(false, activeProjectId, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), searchTerm, activeObjectFilter);
            }
        }
        return response.id;
    };

    const removeSettings = async (id: string) => {
        const response = await dispatch(deleteSetting(id)).unwrap();
        if (response.ok) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.SETTINGS_DELETED);
            loadSettings(false, activeProjectId, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        }
    };

    const _publishSetting = async (id: string) => {
        await dispatch(publishSetting({ settingId: id })).unwrap();
        if (!showUnsaved) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.SETTINGS_PUBLISHED);
        }
    };

    const _abortPublishSetting = async (id: string) => {
        const response = await dispatch(abortPublishSetting({ settingId: id })).unwrap();
        if (response.ok) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.SETTINGS_ABORT_PUBLISH);
        }
        return response;
    };

    const lockSetting = (id: string) => {
        lock(id);
        unlockOnClose(id);
    };

    useEffect(() => {
        if (loading) return;
        if (settingId) {
            const setting = settings.find((s) => s._id === settingId);
            if (!setting) return;
            const isLocked = isObjectLocked(setting);
            const isLockedBy = objectIsLockedBy(setting);
            isLocked && renderLockedWarningAlert(isLockedBy);
            if (!isLocked) {
                setIsEditing(true);
                lockSetting(settingId);
            }
            if (activeObjectFilter?._id) {
                setSearchTerm(translate(setting.name) || `${EMPTY_WORD_STRING} ID: ${setting._id.substring(0, 8)}...`);
            }
            setSettingToEdit(_.cloneDeep(setting));
        }
    }, [settings]);

    useEffect(() => {
        if (loading || error) return;
        setSettings([...storeSettings]);
    }, [loading]);

    useEffect(() => {
        if (_.isEmpty(settingType)) return;

        const sections = [...Object.keys(settingType)] as SettingSectionType[];
        const defaultSettingValues: SettingsValues = {};
        sections.forEach((key) => {
            const subSections = settingType[key] || {};
            const defaultSubSection: any = {};

            Object.keys(subSections).forEach((sKey) => {
                const subSectionUM = subSections[sKey];
                defaultSubSection[sKey] = DefaultSettingValues[subSectionUM];
            });
            defaultSettingValues[key] = defaultSubSection;
        });

        setSectionsToShow(sections);
        setDefaultSetting(defaultSettingValues);
    }, [settingType]);

    useEffect(() => {
        // when changing between projects or onLoad, we cancel any editing state
        handleCancelClick();
        if (!activeProjectId) return;

        resetCallback();

        if (storeSettings.length) {
            dispatch(unsetSettings());
        }

        // Used to open the editor in a new tab
        const redirectSettingId = getSearchParam('id');

        const settingId = location?.state?.settingsId || location?.state?.editingId || redirectSettingId;

        let filter: ObjectFilter = {};
        if (settingId) {
            filter._id = [settingId];
            setActiveObjectFilter(filter);
            setSettingId(settingId);
            window.history.replaceState({}, '');
        }

        loadSettings(true, activeProjectId, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), '', filter).then((response: any) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
            if (!settingsTitles) {
                loadSettingsTitles();
            }
        });
        loadAudiences();
        loadConditions();
        loadTargetGroups();
    }, [activeProjectId]);

    useEffect(() => {
        if (!activeTenantId) return;
        loadSettingType(activeTenantId);
    }, [activeTenantId]);

    useEffect(() => {
        setShowUnsaved(checkUnsaved());
    }, [creatingSetting, settingToEdit]);

    const handleSearch = (filter: ObjectFilter | string) => {
        if (typeof filter === 'string') {
            setSearchTerm(filter);
            loadSettings(false, activeProjectId, orderBy, filter);
        } else {
            if (!Object.keys(filter).length) resetCallback({ sortConfig: true });
            setActiveObjectFilter(filter);
            loadSettings(false, activeProjectId, orderBy, '', filter);
        }
    };

    const checkUnsaved = () => {
        if (creatingSetting) return true;
        if (!settingToEdit) return false;

        const oldSetting = storeSettings.find((setting) => setting._id === settingToEdit?._id);

        // Parsing float so check work correctly
        sectionsToShow.forEach((subsectionKey) => {
            if (!settingToEdit[subsectionKey]) return;
            Object.keys(settingToEdit[subsectionKey]).forEach((key) => {
                if (!settingToEdit[subsectionKey][key]) return;
                const settingUM = settingType?.[subsectionKey]?.[key];
                settingToEdit[subsectionKey][key] =
                    !settingUM || [SettingsUMTypes.BOOLEAN, SettingsUMTypes.FREE_INPUT, SettingsUMTypes.SINGLE_SELECT].includes(settingUM)
                        ? settingToEdit[subsectionKey][key]
                        : parseFloat(settingToEdit[subsectionKey][key] || 0);
            });
        });

        return !_.isEqual(oldSetting, settingToEdit);
    };

    const handleCreateClick = () => {
        settingToEdit?._id && unlock(settingToEdit._id);
        setCreatingSetting(true);
        setIsEditing(true);
        const creatingSetting = { ...defaultSetting };

        setSettingToEdit(_.cloneDeep(creatingSetting));
    };

    const handleDuplicateClick = (setting: Setting) => {
        settingToEdit?._id && unlock(settingToEdit._id);
        setCreatingSetting(true);
        setIsEditing(true);
        setSettingToEdit({ ..._.cloneDeep(_.omit(setting, 'locked')), _id: '-1' });
    };

    const expandSetting = (setting: any) => {
        if (setting?._id === settingToEdit?._id) {
            setIsEditing(false);
            setSettingToEdit(undefined);
            setExpandedSubsectionKeys(null);
            unlock(setting._id);
            return;
        }
        setSettingToEdit(_.cloneDeep(setting));
        setExpandedSubsectionKeys(null);
        lockSetting(setting._id);
    };

    const renderAlertUnsavedChanges = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSaveClick();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },

            () => {
                handleCancelClick();
            },
            true
        );
    };

    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Remove Setting',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                removeSettings(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Setting?</strong>
                    <br />
                    By Pressing “Remove” you will still be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const handleCancelClick = () => {
        if (!creatingSetting && settingToEdit?._id) {
            unlock(settingToEdit._id);
        }
        setExpandedSubsectionKeys(null);
        setCreatingSetting(false);
        setIsEditing(false);
        setSettingToEdit(undefined);
        setSettingId('');
    };

    const handlePublishClick = async (settingId: string) => {
        let sId;
        if (showUnsaved) {
            // if there are changes, save the setting first, then publish it
            sId = await handleSaveClick(undefined, false, true);
        }
        await _publishSetting(sId || settingId);
        handleCancelClick();
        creatingSetting && resetCallback();
        loadSettings(
            false,
            activeProjectId,
            creatingSetting ? '' : orderBy,
            creatingSetting ? '' : searchTerm,
            creatingSetting ? undefined : activeObjectFilter
        );
    };
    const handleAbortPublishSettingClick = async (id: string) => {
        await _abortPublishSetting(id);
        loadSettings(false, activeProjectId, orderBy, searchTerm, activeObjectFilter);
        handleCancelClick();
    };

    const handleSaveClick = (publishAt?: number, reload?: boolean, publishing?: boolean): Promise<string> | undefined => {
        if (!settingToEdit) return;
        const oldSetting = settings.find((s) => s._id === settingToEdit._id) || null;

        return new Promise((resolve) => {
            const onSave = async (addObjectToGroup?: boolean) => {
                const id = await handleSave(publishAt, reload, addObjectToGroup, publishing);
                resolve(id || '');
            };

            handleCheckBeforeSave(storeAudiences, targetGroups, oldSetting, settingToEdit, 'settings', onSave);
        });
    };

    const handleSave = (publishAt?: number, reload?: boolean, addObjectToGroup?: boolean, publishing?: boolean) => {
        const settingToSave: any = {
            ...settingToEdit,
            _id: settingToEdit?._id === '-1' ? '' : settingToEdit?._id || '',
            name: settingToEdit?.name || '',
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            publishAt: publishAt,
            conditionIds: settingToEdit?.conditionIds || []
        };

        sectionsToShow.forEach((subsectionKey) => {
            if (!settingToSave[subsectionKey]) return;
            Object.keys(settingToSave[subsectionKey]).forEach((key) => {
                if (!settingToSave[subsectionKey][key]) return;
                const settingUM = settingType?.[subsectionKey]?.[key];
                settingToSave[subsectionKey][key] =
                    !settingUM || [SettingsUMTypes.BOOLEAN, SettingsUMTypes.FREE_INPUT, SettingsUMTypes.SINGLE_SELECT].includes(settingUM)
                        ? settingToSave[subsectionKey][key]
                        : parseFloat(settingToSave[subsectionKey][key] || 0);
            });
        });

        if (settingToSave?.login && !settingToSave?.login?.qrLogin) {
            settingToSave.login.qrLoginCodeUrl = '';
        }

        if (settingToSave?.login && !settingToSave?.login?.credentialLogin) {
            settingToSave.login.credentialLoginForgotPasswordUrl = '';
        }

        handleCancelClick();
        return creatingSetting
            ? saveSetting(settingToSave, reload, addObjectToGroup, publishing)
            : modifySetting(settingToSave, reload, addObjectToGroup, publishing);
    };

    const toggleArrows = () => {
        setShowSortArrows(!showSortArrows);
    };

    const handleSortIconClick = (field: AcceptedSortField) => {
        if (showUnsaved) return renderUnsavedAlertBeforeAction();

        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadSettings(false, activeProjectId, orderBy, searchTerm, activeObjectFilter);
        setShowSortArrows(false);
        setExpandedSubsectionKeys(null);
        setSettingToEdit(undefined);
        setSettingId('');
    };

    const handleSubSectionOnChange = (evt: any, subsectionKey: SettingSectionType, key: string) => {
        const value = evt.target.value;
        const settingUM = settingType?.[subsectionKey]?.[key];
        const isNumber = settingUM && ![SettingsUMTypes.BOOLEAN, SettingsUMTypes.FREE_INPUT].includes(settingUM);
        if (isNumber && !value.match(/^([0-9]+)?(\.)?([0-9])?$/)) return;
        handleSettingInputChange(subsectionKey, key, value);
    };

    const renderNoSettings = () => (
        <NoResourcesContainer>
            <ResourceCard
                image={resourceCardImages.menusCard}
                title={'New Setting'}
                subtitle={'Create setting from scratch'}
                primaryButtonLabel={'Create Setting'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    handleCreateClick();
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.menus);
                }}
                createResource
            />
        </NoResourcesContainer>
    );

    const buildTableColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'Settings Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => toggleArrows()}
                onMouseLeave={() => toggleArrows()}
                columnSize={SettingsTableSizes['name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <HeaderTableCell key={'placed_cell'} text={'Part of Group(s)'} columnSize={SettingsTableSizes.placed} />,
            <HeaderTableCell key={'conditions_cell'} text={'Target Conditions'} columnSize={SettingsTableSizes.conditions} />,
            <SortableHeaderTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => toggleArrows()}
                onMouseLeave={() => toggleArrows()}
                columnSize={SettingsTableSizes['lastModified']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];
        return columns;
    };

    const buildTableBody = () => {
        return (
            <>
                {settings?.map((setting: any, index) => {
                    const dateString = generateDateStringForTables(setting.lastModified);
                    const expanded = settingToEdit?._id === setting._id;
                    const isLocked = isObjectLocked(setting);
                    const isLockedBy = objectIsLockedBy(setting);
                    const isLast = settings.length - 1 === index;

                    return (
                        <>
                            <ScrollableSettingsContainer key={setting._id} shouldScroll={settingId === setting._id}>
                                {/* SETTING TITLE TABLE CELL */}
                                {/*In case of editing, the input field is rendered*/}
                                {expanded && isEditing ? (
                                    <WidthTableCell colSpan={4} $um={'px'}>
                                        <SettingEditContainer>
                                            <DialogTextFieldWrapper>
                                                <DialogTextField
                                                    label={'Settings Name'}
                                                    value={settingToEdit?.name || ''}
                                                    onChange={(evt: any) => {
                                                        setSettingToEdit({ ..._.set(settingToEdit, 'name', evt.target.value) });
                                                    }}
                                                    placeholder={'Settings Name'}
                                                />
                                            </DialogTextFieldWrapper>
                                            <SettingsConditionsView>
                                                <TargetConditionsSelect
                                                    targets={settingToEdit?.conditionIds || []}
                                                    onChange={(val) => {
                                                        setSettingToEdit({
                                                            ..._.set(settingToEdit, 'conditionIds', val)
                                                        });
                                                    }}
                                                />
                                            </SettingsConditionsView>
                                            <AbTestingSelectWrapper>
                                                <ABTestingGroupSelect
                                                    selectedTargetConditions={settingToEdit?.conditionIds || []}
                                                    selectedGroups={settingToEdit?.abTestingGroupIds || []}
                                                    onChange={(val) => {
                                                        setSettingToEdit({
                                                            ..._.set(settingToEdit, 'abTestingGroupIds', val)
                                                        });
                                                    }}
                                                />
                                            </AbTestingSelectWrapper>
                                        </SettingEditContainer>
                                    </WidthTableCell>
                                ) : (
                                    <>
                                        {/*  SETTING NAME TABLE CELL */}
                                        <WidthTableCell {...SettingsTableSizes.name}>
                                            <SettingTitleContainer
                                                onClick={() => {
                                                    if (showUnsaved) return renderAlertUnsavedChanges();
                                                    expandSetting(setting);
                                                    if (isLocked && setting._id !== settingToEdit?._id) {
                                                        return renderLockedWarningAlert(isLockedBy);
                                                    }
                                                }}
                                            >
                                                <TruncatedText>{setting.name || EMPTY_WORD_STRING}</TruncatedText>
                                                {isLocked && renderLockIcon(isLockedBy)}
                                                {renderABIcon(setting)}
                                            </SettingTitleContainer>
                                        </WidthTableCell>

                                        {/* PLACED TABLE CELL */}
                                        <WidthTableCell {...SettingsTableSizes.placed}>
                                            <Labels
                                                type={MoreInfoTypes.PLACED}
                                                values={setting?.placed || []}
                                                noOfLabels={isLargeDesktop ? 4 : isDesktop ? 2 : isMobile ? 0 : 1}
                                                onClickLabel={(obj) => {
                                                    navigate(
                                                        buildPathWithProjectId(
                                                            activeProjectId,
                                                            PageRoutes.TARGET_GROUP.replace(':group_id', obj._id)
                                                        )
                                                    );
                                                }}
                                            />
                                        </WidthTableCell>

                                        {/* CONDITIONS TABLE CELL */}
                                        <WidthTableCell {...SettingsTableSizes.conditions}>
                                            <Labels
                                                type={MoreInfoTypes.TARGETS}
                                                values={setting?.conditions || []}
                                                noOfLabels={isDesktop ? 3 : isMobile ? 0 : 1}
                                                onClickLabel={(obj) => {
                                                    const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                                    navigate(
                                                        buildPathWithProjectId(
                                                            activeProjectId,
                                                            isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                                        ),
                                                        {
                                                            state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                                        }
                                                    );
                                                }}
                                            />
                                        </WidthTableCell>

                                        {/* LAST MODIFIED TABLE CELL */}
                                        <WidthTableCell {...SettingsTableSizes.lastModified}>
                                            <LastModified>
                                                {dateString}
                                                <Tooltip title={setting?.modifiedByUser?.name || ''} placement="right-start">
                                                    <img src={setting?.modifiedByUser?.icon || icons.avatarIcon} alt={''} />
                                                </Tooltip>
                                            </LastModified>
                                        </WidthTableCell>
                                    </>
                                )}

                                {/* ACTIONS TABLE CELL */}
                                <WidthTableCell {...SettingsTableSizes.actions}>
                                    <ObjectActions
                                        actions={[tableActions.EDIT, tableActions.REMOVE]}
                                        withArrow
                                        onRemove={() => {
                                            if (isLocked) {
                                                return renderLockedWarningAlert(isLockedBy);
                                            }
                                            showUnsaved ? renderAlertUnsavedChanges() : handleDeleteClick(setting._id);
                                        }}
                                        onEdit={() => {
                                            if (showUnsaved && settingToEdit?._id !== setting._id) return renderAlertUnsavedChanges();
                                            isLocked
                                                ? renderLockedWarningAlert(isLockedBy)
                                                : setIsEditing(setting._id === settingToEdit?._id ? !isEditing : true);
                                            if (settingToEdit?._id !== setting._id) {
                                                expandSetting(setting);
                                                lockSetting(setting._id);
                                            }
                                        }}
                                        open={expanded}
                                        onArrowToggle={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            expandSetting(setting);
                                            if (isLocked && setting._id !== settingToEdit?._id) {
                                                return renderLockedWarningAlert(isLockedBy);
                                            }
                                        }}
                                        tooltipTexts={{
                                            delete: 'settings_icon_delete',
                                            edit: 'settings_icon_edit',
                                            arrowClose: 'settings_icon_hide',
                                            arrowOpen: 'settings_icon_show'
                                        }}
                                        publishedStatus={setting.publishStatus}
                                        publishAt={setting.publishAt}
                                    />
                                </WidthTableCell>
                            </ScrollableSettingsContainer>

                            {/* SUBSECTIONS TABLE ROW */}
                            {expanded && (
                                <>
                                    <TableRowWrapper>
                                        <WidthTableCell style={{ padding: 0 }} colSpan={5} $um={'px'}>
                                            {renderSubSections(settingToEdit)}
                                        </WidthTableCell>
                                    </TableRowWrapper>
                                </>
                            )}

                            {/* SPACE BETWEEN ROWS */}
                            {!isLast && <TableRow style={{ height: '24px' }} />}
                        </>
                    );
                })}

                {/* CREATE SETTING ROW */}
                {creatingSetting && renderCreatingSetting()}
            </>
        );
    };
    const renderSubSections = (setting: any) => {
        if (!setting) return;

        const sections: JSX.Element[] = [];

        sectionsToShow.forEach((subsectionKey) => {
            if (!defaultSetting[subsectionKey]) return;
            const expanded = expandedSubsectionKeys?.[subsectionKey];
            const tooltipText = expanded ? `settings_${subsectionKey}_icon_hide` : `settings_${subsectionKey}_icon_show`;
            const isLocked = isObjectLocked(setting);
            const isLockedBy = objectIsLockedBy(setting);

            sections.push(
                <>
                    <SettingsSubsection $expanded={expanded}>
                        <SettingSubsectionName
                            onClick={() => {
                                isLocked && !expandedSubsectionKeys?.[subsectionKey] && renderLockedWarningAlert(isLockedBy);
                                setExpandedSubsectionKeys({
                                    ...expandedSubsectionKeys,
                                    [subsectionKey]: !expandedSubsectionKeys?.[subsectionKey]
                                });
                            }}
                        >
                            {SUBSECTION_NAMES[subsectionKey]}
                        </SettingSubsectionName>
                        {renderTooltipWithKey(
                            <SVGInline
                                src={expanded ? icons.arrowUpIcon : icons.arrowDownIcon}
                                onClick={() => {
                                    isLocked && !expandedSubsectionKeys?.[subsectionKey] && renderLockedWarningAlert(isLockedBy);
                                    setExpandedSubsectionKeys({
                                        ...expandedSubsectionKeys,
                                        [subsectionKey]: !expandedSubsectionKeys?.[subsectionKey]
                                    });
                                }}
                            />,
                            tooltipText
                        )}
                    </SettingsSubsection>
                    {expanded && <div>{renderExpandedSubsection(setting, subsectionKey)}</div>}
                </>
            );
        });

        return <SettingsSubsectionsHolder>{sections}</SettingsSubsectionsHolder>;
    };

    const handleSettingInputChange = (subsection: string, key: string, value: string | boolean | string[]) => {
        if (subsection !== settingsSections.LOGIN) {
            return setSettingToEdit({ ..._.set(settingToEdit, `${subsection}.${key}`, value) });
        }

        const settingToUpdate = { ...settingToEdit };
        // When the following checkboxes are unchecked, the corresponding text fields must be cleared
        if (key === 'qrLogin' && !value) {
            settingToUpdate.login.qrLoginCodeUrl = '';
        }

        if (key === 'credentialLogin' && !value) {
            settingToUpdate.login.credentialLoginForgotPasswordUrl = '';
        }

        _.set(settingToUpdate, `${subsection}.${key}`, value);
        setSettingToEdit(settingToUpdate);
    };

    const renderSettingField = (setting: any, subsectionKey: SettingSectionType, key: string) => {
        const settingUM = settingType?.[subsectionKey]?.[key];
        const settingName = settingsTitles?.[key] || key;
        const settingValue = setting?.[subsectionKey]?.[key] ?? defaultSetting?.[subsectionKey]?.[key];
        const settingTooltip = SETTINGS_TOOLTIPS?.[key] || '';

        let field: JSX.Element | null = null;
        switch (settingUM) {
            case SettingsUMTypes.BOOLEAN:
                field = (
                    <DialogCheckbox
                        onClick={() => {
                            handleSettingInputChange(subsectionKey, key, !settingValue);
                        }}
                        value={settingValue}
                        active={settingValue}
                        text={''}
                        disabled={
                            // one login field is always mandatory so the checkbox is disabled if it is the only one active
                            loginSubsectionKeys.includes(key) &&
                            !loginSubsectionKeys
                                .filter((lKey) => lKey !== key)
                                .some(
                                    (lKey) =>
                                        Object.keys(settingToEdit?.[subsectionKey] || {}).includes(lKey) &&
                                        settingToEdit?.[subsectionKey]?.[lKey]
                                )
                        }
                    />
                );
                break;
            case SettingsUMTypes.SINGLE_SELECT:
                const singleSelectOptions = settingsDropdownOptions[key] || [];
                field = (
                    <DialogDropdownSingle
                        options={singleSelectOptions || []}
                        value={singleSelectOptions.find((opt: any) => opt.value === settingValue) || ''}
                        placeholder=""
                        onChange={(val: any) => {
                            handleSettingInputChange(subsectionKey, key, val.value);
                        }}
                        clearable
                    />
                );
                break;
            case SettingsUMTypes.FREE_INPUT:
            case SettingsUMTypes.INTEGER:
            case SettingsUMTypes.PERCENTAGE:
            case SettingsUMTypes.SECONDS:
                field = (
                    <DialogTextField
                        noLabel
                        value={`${settingValue || ''}`}
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => handleSubSectionOnChange(evt, subsectionKey, key)}
                        InputProps={{
                            endAdornment: (
                                <SubsectionInputAdornment>
                                    {settingKeysWithTranslations.includes(key) && (
                                        <TranslationTooltip
                                            translationKey={settingValue || ''}
                                            showNewTranslationKey
                                            handleKeyChange={(val) =>
                                                handleSubSectionOnChange({ target: { value: val } }, subsectionKey, key)
                                            }
                                            isTextField
                                        />
                                    )}
                                    <InputAdornmentText>
                                        {settingUM === SettingsUMTypes.INTEGER
                                            ? `${_.capitalize(settingUM)}`
                                            : _.capitalize(settingUM) || SettingsUMTypes.FREE_INPUT}
                                    </InputAdornmentText>
                                </SubsectionInputAdornment>
                            )
                        }}
                    />
                );
                break;
            default:
                break;
        }

        return (
            <>
                <SettingsSubsectionRowName>
                    <TruncatedText> {settingName} </TruncatedText>
                    {settingTooltip && renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, settingTooltip)}{' '}
                </SettingsSubsectionRowName>
                {field}
            </>
        );
    };

    const renderExpandedSubsection = (setting: any, subsectionKey: SettingSectionType) => {
        if (!subsectionKey && !defaultSetting[subsectionKey]) return;

        if (subsectionKey === settingsSections.LOGIN) {
            return Object.keys(groupedLoginSubsections).map((key) => {
                return (
                    <SettingsSubsectionRow key={`${subsectionKey}_${key}`}>
                        {groupedLoginSubsections[key].map((value) => {
                            // when the checkbox is not active we should hide the input for that group section
                            if (!settingToEdit?.[subsectionKey]?.[key] && value !== key) return <></>;
                            return (
                                <GroupedSettingSubsection key={value}>
                                    {renderSettingField(setting, subsectionKey, value)}
                                </GroupedSettingSubsection>
                            );
                        })}
                    </SettingsSubsectionRow>
                );
            });
        }

        return Object.keys(defaultSetting[subsectionKey] || {}).map((key) => {
            return (
                <SettingsSubsectionRow key={`${subsectionKey}_${key}`}>
                    {renderSettingField(setting, subsectionKey, key)}
                </SettingsSubsectionRow>
            );
        });
    };
    const renderCreatingSetting = () => {
        const expanded = !settingToEdit?._id;
        return (
            <>
                <TableRow style={{ height: '16px' }} />
                <ScrollableSettingsContainer shouldScroll={creatingSetting} key={'Creating_setting'} id={'create-setting-container'}>
                    <WidthTableCell colSpan={4} $um={'px'}>
                        <SettingEditContainer>
                            <DialogTextFieldWrapper>
                                <DialogTextField
                                    label={'Settings Name'}
                                    value={settingToEdit?.name || ''}
                                    onChange={(evt: any) => {
                                        setSettingToEdit({ ..._.set(settingToEdit, 'name', evt.target.value) });
                                    }}
                                    placeholder={'Settings Name'}
                                />
                            </DialogTextFieldWrapper>
                            <SettingsConditionsView>
                                <TargetConditionsSelect
                                    targets={settingToEdit?.conditionIds || []}
                                    onChange={(val) => {
                                        setSettingToEdit({
                                            ..._.set(settingToEdit, 'conditionIds', val)
                                        });
                                    }}
                                />
                            </SettingsConditionsView>
                            <AbTestingSelectWrapper>
                                <ABTestingGroupSelect
                                    selectedGroups={settingToEdit?.abTestingGroupIds || []}
                                    selectedTargetConditions={settingToEdit?.conditionIds || []}
                                    onChange={(val) => {
                                        setSettingToEdit({
                                            ..._.set(settingToEdit, 'abTestingGroupIds', val)
                                        });
                                    }}
                                />
                            </AbTestingSelectWrapper>
                        </SettingEditContainer>
                    </WidthTableCell>
                    <WidthTableCell {...SettingsTableSizes.actions}>
                        <ObjectActions
                            actions={[tableActions.REMOVE]}
                            withArrow
                            onRemove={() => {
                                showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                            }}
                            open={expanded}
                            onArrowToggle={() => {
                                if (showUnsaved) return renderAlertUnsavedChanges();
                                setSettingToEdit(undefined);
                                setExpandedSubsectionKeys(null);
                            }}
                            tooltipTexts={{
                                delete: 'settings_icon_delete',
                                arrowClose: 'settings_icon_hide',
                                arrowOpen: 'settings_icon_show'
                            }}
                        />
                    </WidthTableCell>
                </ScrollableSettingsContainer>

                {/* SUBSECTIONS TABLE ROW */}
                {expanded && (
                    <>
                        <TableRowWrapper>
                            <WidthTableCell style={{ padding: 0 }} colSpan={5} $um={'px'}>
                                {renderSubSections(settingToEdit)}
                            </WidthTableCell>
                        </TableRowWrapper>
                    </>
                )}
            </>
        );
    };

    const titleJSX = <>Settings {showUnsaved && <UnsavedSpan>&nbsp;&nbsp;[Unsaved]</UnsavedSpan>}</>;
    return (
        <>
            {error && (error.code === API_ERROR_CODES.LOCKED_ERROR ? renderLockedError(error) : <BackendErrorDialog error={error} />)}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        loading={loading}
                        title={titleJSX}
                        withAddButton={storeSettings?.length !== 0}
                        withProfile
                        addLabel={'Create Settings'}
                        onAdd={() => {
                            showUnsaved ? renderAlertUnsavedChanges() : handleCreateClick();
                        }}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.settings, onboarding: ONBOARDING_CIRCLE_SLUGS.settings }}
                    />
                    <SearchBarContainer>
                        <SearchBarWrapper>
                            <FancyFilter
                                searchTerm={searchTerm}
                                onChange={handleSearch}
                                showUnsaved={showUnsaved}
                                type={ObjectTypes.SETTINGS}
                                setSearchTerm={setSearchTerm}
                                activeObjectFilter={activeObjectFilter}
                            />
                        </SearchBarWrapper>
                    </SearchBarContainer>

                    {loading ? (
                        <Loader title="Settings" />
                    ) : !settings.length && !creatingSetting ? (
                        renderNoSettings()
                    ) : (
                        <>
                            <GenericTable columns={buildTableColumns()} body={buildTableBody()} />
                            {!creatingSetting && (
                                <PageActionsWrapper>
                                    <PageActionButton
                                        type={'BLUE'}
                                        label={'Create Settings'}
                                        onClick={() => {
                                            handleCreateClick();
                                        }}
                                    />
                                </PageActionsWrapper>
                            )}
                        </>
                    )}
                </MainContentWrapper>
                {(creatingSetting || settingToEdit) && (
                    <PageActions
                        onSave={() => {
                            handleSaveClick();
                        }}
                        publishedStatus={settingToEdit.publishStatus || PUBLISHED_STATUS.UNPUBLISHED}
                        onAbortPublish={() => handleAbortPublishSettingClick(settingToEdit._id)}
                        onPublish={(publishAt) => {
                            publishAt ? handleSaveClick(publishAt, undefined, true) : handlePublishClick(settingToEdit._id);
                        }}
                        timestamp={settingToEdit.publishAt}
                        withSchedule
                        onCancel={() => (showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick())}
                        disabled={{
                            save: !showUnsaved || conditionsLoading,
                            publish: settingToEdit?.publishStatus === PUBLISHED_STATUS.PUBLISHED && !showUnsaved
                        }}
                    />
                )}
            </ApplicationWrapper>
        </>
    );
};

export default Settings;
