import React, { useState } from 'react';
import icons from '../../../../assets/images/icons';
import { TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';

import SVGInline from 'react-inlinesvg';
import { generateDateStringForTables } from '../../../../utils/fnDate';
import {
    AudienceListContainer,
    AudienceTitleContainer,
    AudiencesWrapper,
    GridView,
    ItemCard,
    ItemIcon,
    ItemName,
    ItemType,
    LastModified,
    ListItemContainer,
    ListView
} from './Audiences.css';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { audiencesState, createAudience, fetchAudiences, updateAudience } from '../../../../redux/slices/audienceSlice';
import { ActiveItemState } from '../../../../redux/slices/activeItemSlice';
import BackendErrorDialog from '../../../common/Dialog/BackendErrorDialog';
import { Audience, AudienceFilterHeaders, audienceFilterKeys } from '../../../../types/Audience';
import _ from 'lodash';
import { DIALOG_NAMES, ToastAlert, dialogAlert, dialogConfirm } from '../../../../utils/fnDialogs';

import NewAudience from '../../Dialogs/NewAudience';
import { fetchTargetGroup, targetGroupsState } from '../../../../redux/slices/targetGroupsSlice';
import { API_ERROR_CODES, EMPTY_WORD_STRING } from '../../../../utils/Globals';
import { TargetingGuide } from './Dialogs/TargetingGuide';
import configServiceAPI from '../../../../utils/api/configServiceAPI';
import { clientTokenGenerator } from '../../../../utils/fnData';
import CreateResourceDialog from '../../../common/Dialog/CreateResourceDialog';
import UseExistingDialog, { EXISTING_ITEMS } from '../../../PageEdit/Dialogs/UseExistingDialog';
import { RemoveModuleWrapper } from '../../../Modules/Modules.css';
import { ObjectListHeader, ObjectListItem, ObjectListWrapper, TruncatedText } from '../../../../style/styled-components/reusable.css';
import { audiencesIcons } from '../../../../assets/images/icons/audiencesIcons';
import useLockSystem, { LockableObjectTypes } from '../../../../hooks/useLockSystem';
import { renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../../../utils/fnLockingSystem';
import { Page } from '../../../../types/Page';
import { Setting } from '../../../../types/Setting';
import { Menu } from '../../../../types/Menu';
import { ObjectActions } from '../../../common/Actions/Actions';
import GenericTable, { tableActions } from '../../../common/Table/Table';
import { GroupAudiencesTableSizes } from '../../../../types/TableSizes';
import { TableRowWrapper, TitleTableRow, WidthTableCell } from '../../../common/Table/Table.css';
import { AddElementCell } from '../../GroupEdit.css';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';
import { useNavigate } from 'react-router-dom';
import { PageRoutes, buildPathWithProjectId } from '../../../../types/RouteTypes';
import useTranslation from '../../../../hooks/useTranslation';
import { FeatureFlag } from '../../../../types/FeatureFlags';

export type AudienceProps = {
    viewType: 'LIST' | 'GRID';
    unsavedChanges: boolean;
    groupAudiences: Audience[];
    filteredAudiences?: Audience[];
    groupId?: string;
    resetSearch?: () => void;
};

export const audienceVersionTypeSymbols: any = {
    greater_than: '>',
    lower_than: '<',
    in_between: '-'
};

export const Audiences: React.FC<AudienceProps> = ({
    viewType,
    groupId,
    groupAudiences,
    filteredAudiences,
    unsavedChanges,
    resetSearch
}) => {
    const { audiences: allAudiences }: audiencesState = useAppSelector((state) => state.audiences);
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state: any) => state.activeItem);
    const { error: audiencesError }: audiencesState = useAppSelector((state) => state.audiences);

    const { selectedTargetGroup, loadingGroup }: targetGroupsState = useAppSelector((state) => state.targetGroups);
    const [openNewResourceDialog, setOpenNewResourceDialog] = useState<boolean>(false);
    const [openExistingDialog, setOpenExistingDialog] = useState<boolean>(false);

    const [expandedAudience, setExpandedAudience] = useState<Audience | undefined>(undefined);
    const [creatingAudience, setCreatingAudience] = useState<boolean>(false);

    // Tracks whether the top values can be edited or not
    const { isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.AUDIENCES);

    const [openNewAudienceDialog, setOpenNewAudienceDialog] = useState(false);
    const [audienceGuide, setAudienceGuide] = useState<Audience | undefined>(undefined);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { translate } = useTranslation();

    const loadGroup = async (groupId: string) => {
        await dispatch(fetchTargetGroup({ targetGroupId: groupId })).unwrap();
    };

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId }));
    };

    const modifyAudience = async (newAudience: Audience, addAudienceToGroupObjects: boolean = false) => {
        await dispatch(updateAudience({ audience: newAudience, shouldUnlockAfterSave: true, addAudienceToGroupObjects })).unwrap();
        groupId && loadGroup(groupId);
        loadAudiences();
    };

    const loadProjectToken = async () => {
        let token = '';
        const { response } = await configServiceAPI.getToken(activeProjectId || '');
        if (response) {
            token = (response as any).token;
        }
        return token;
    };
    const saveAudience = async (newAudience: Audience, addAudienceToGroupObjects: boolean = false) => {
        const response = await dispatch(createAudience({ audience: newAudience, addAudienceToGroupObjects })).unwrap();
        if (!response.id) {
            const values = {
                title: 'Oops.. An error occured.',
                text: response.error
            };
            return dialogAlert('', false, values, undefined, true);
        }
        groupId && loadGroup(groupId);
        loadAudiences();
    };

    const removeAudience = async (id: string) => {
        const audienceToEdit = groupAudiences.find((audience) => audience._id === id);
        const newAudience = { ...audienceToEdit, targetGroupId: '' } as Audience;
        await dispatch(updateAudience({ audience: newAudience, shouldUnlockAfterSave: true })).unwrap();
        groupId && loadGroup(groupId);
        loadAudiences();
        resetSearch?.();
    };

    const renderAlertUnsavedChanges = () => {
        const values = {
            title: 'Action is blocked',
            text: 'You have unsaved changes, please save before proceeding with this action!'
        };
        ToastAlert('warning', values.title, values.text, undefined, undefined, () => {
            dialogAlert('', false, values, null, false, icons.warningYellowIcon);
        });
    };

    const purgeCdnForAudience = async (audience: any) => {
        const headers = Object.keys(audience).reduce((prev, key) => {
            const newHeaders = { ...prev };
            if (audienceFilterKeys.includes(key)) {
                _.set(newHeaders, _.get(AudienceFilterHeaders, key), _.get(audience, key));
            }
            return newHeaders;
        }, {});
        const apiKey = await loadProjectToken();
        const token = clientTokenGenerator(apiKey, headers, AudienceFilterHeaders);
        const response = await configServiceAPI.purgeCdn(token, audience._id);
        if (!response.error) {
            dialogAlert(DIALOG_NAMES.AUDIENCE_PURGE_SUCCESS);
        } else {
            if (response.error.code === API_ERROR_CODES.LOCKED_ERROR) {
                return renderLockedWarningAlert(response.error.data.name);
            }
            dialogAlert(DIALOG_NAMES.AUDIENCE_PURGE_ERROR);
        }
    };

    const expandAudience = (audience: Audience) => {
        setExpandedAudience(audience._id === expandedAudience?._id ? undefined : audience);
    };

    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Remove Audience from Group',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                removeAudience(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Audience from the Group?</strong>
                    <br />
                    By pressing “Remove” the Audience will be removed ONLY from the Group.
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const splitAudienceKeys = (s: string) => {
        return (s.charAt(0).toUpperCase() + s.slice(1)).match(/[A-Z][a-z]+/g)?.join(' ');
    };

    const calculateAudienceValues = (audienceValue: any) => {
        if (typeof audienceValue !== 'object') return audienceValue;
        if (Array.isArray(audienceValue) && audienceValue.length) {
            return audienceValue.slice(0, 3).join(', ') + (audienceValue.length > 3 ? '...' : '');
        }
        if (['type', 'firstVersion', 'secondVersion'].some((r) => Object.keys(audienceValue).indexOf(r) >= 0)) {
            const type = audienceValue['type'];
            if (Object.keys(audienceValue).length > 2) {
                return `${audienceValue['firstVersion']} ${audienceVersionTypeSymbols[type]} ${audienceValue?.secondVersion}`;
            }
            return ` ${audienceVersionTypeSymbols[type]} ${audienceValue['firstVersion']} `;
        }
        return (Object.keys(audienceValue) as Array<keyof typeof audienceValue>).map((k, i) => {
            if (!audienceValue) return;
            return <span key={`${i}_value`}>{`${audienceValue[k]} `}</span>;
        });
    };

    const handleSaveClick = (audience?: Audience, addAudienceToGroupObjects: boolean = false) => {
        if (!audience) return;

        const audToSave = { ...audience, targetGroupId: selectedTargetGroup?._id || '' };
        if (creatingAudience) {
            saveAudience(audToSave, addAudienceToGroupObjects);
        } else {
            modifyAudience(audToSave, addAudienceToGroupObjects);
        }
        handleCancelClick();
        resetSearch?.();
    };

    const handleCheckBeforeSave = (audience?: Audience) => {
        if (!selectedTargetGroup) return;
        if (!audience) return;

        const { pages, menus, settings, featureFlags } = selectedTargetGroup;
        const pagesWithoutCurrentAudience = creatingAudience
            ? (pages as Page[])
            : (pages as Page[])?.filter((page) => !page.conditionIds?.includes(audience._id));
        const menusWithoutCurrentAudience = creatingAudience
            ? (menus as Menu[])
            : (menus as Menu[])?.filter((menu) => !menu.conditionIds?.includes(audience._id));
        const settingsWithoutCurrentAudience = creatingAudience
            ? (settings as Setting[])
            : (settings as Setting[])?.filter((setting) => !setting.conditionIds?.includes(audience._id));
        const featureFlagsWithoutCurrentAudience = creatingAudience
            ? (featureFlags as FeatureFlag[])
            : (featureFlags as FeatureFlag[])?.filter((flag) => !flag.conditionIds?.includes(audience._id));

        // If everything is alright, proceed with saving and return
        if (
            !pagesWithoutCurrentAudience.length &&
            !menusWithoutCurrentAudience.length &&
            !settingsWithoutCurrentAudience.length &&
            !featureFlagsWithoutCurrentAudience.length
        ) {
            handleSaveClick(audience);
            return;
        }

        const objectsWithoutCurrentAudience = {
            pages: pagesWithoutCurrentAudience,
            menus: menusWithoutCurrentAudience,
            settings: settingsWithoutCurrentAudience,
            featureFlags: featureFlagsWithoutCurrentAudience
        };

        const values = {
            title: "Some objects don't have this Audience",
            subtitle: `Do you wish to add audience "${audience?.name || EMPTY_WORD_STRING}" to the following objects?`
        };

        const children = Object.entries(objectsWithoutCurrentAudience).map(([objKey, objArray]) =>
            objArray.length ? (
                <ObjectListWrapper key={`${objKey}`}>
                    <ObjectListHeader>{_.startCase(objKey)}:</ObjectListHeader>
                    <>
                        {(objArray as any[]).map((obj) => (
                            <ObjectListItem key={obj._id}>- {translate(obj.name || EMPTY_WORD_STRING)}</ObjectListItem>
                        ))}
                    </>
                </ObjectListWrapper>
            ) : null
        );

        dialogConfirm(
            '',
            () => {
                handleSaveClick(audience, true);
            },
            values,
            children,
            { confirmButton: { label: 'Add' }, noButton: { label: "Don't add" } },
            undefined,
            () => {
                handleSaveClick(audience);
            }
        );
    };

    const handleCancelClick = () => {
        setExpandedAudience(undefined);
    };

    const renderAudienceListView = (audience?: Audience) => {
        if (!audience) return;
        const items: any = [];
        (Object.keys(audience) as Array<keyof typeof audience>).map((key, index) => {
            if (audienceFilterKeys.includes(key)) {
                const itemValue = calculateAudienceValues(audience[key]);
                const iconKey = ['deviceClass', 'deviceType', 'operatingSystem'].includes(key) && audience[key];

                items.push(
                    <AudienceListContainer key={`${index}_listview_item`}>
                        <ListItemContainer>
                            <ItemName key={key}>
                                <ItemIcon>
                                    <SVGInline
                                        src={!!iconKey ? audiencesIcons[key][iconKey as any] : audiencesIcons[key] || icons.valueIcon}
                                    />
                                </ItemIcon>
                                {_.truncate(itemValue, { length: 25 })}
                            </ItemName>
                            <ItemType>{splitAudienceKeys(key)}</ItemType>
                        </ListItemContainer>
                    </AudienceListContainer>
                );
            }
        });
        return <ListView>{items}</ListView>;
    };

    const renderAudienceGridView = (audience?: Audience) => {
        if (!audience) return;
        const items: any = [];
        (Object.keys(audience) as Array<keyof typeof audience>).map((key, index) => {
            if (audienceFilterKeys.includes(key) && audience[key]) {
                const itemValue = calculateAudienceValues(audience[key]);
                const iconKey = ['deviceClass', 'deviceType', 'operatingSystem'].includes(key) && audience[key];
                items.push(
                    <ItemCard key={`${index}_gridview_item`}>
                        <ItemIcon>
                            <SVGInline src={!!iconKey ? audiencesIcons[key][iconKey as any] : audiencesIcons[key] || icons.valueIcon} />
                        </ItemIcon>
                        <div>
                            <ItemName>{_.truncate(itemValue, { length: 18 })}</ItemName>
                            <ItemType>{_.truncate(splitAudienceKeys(key), { length: 18 })}</ItemType>
                        </div>
                    </ItemCard>
                );
            }
        });

        return <GridView>{items}</GridView>;
    };

    const buildTableRows = () => {
        const audiencesToShow = filteredAudiences || groupAudiences;
        return (
            <TableBody>
                <TitleTableRow>
                    <TableCell colSpan={3}>{`Manage Audiences`}</TableCell>
                </TitleTableRow>
                <TableRow style={{ height: '8px' }} />
                {audiencesToShow.map((audience: any, index) => {
                    const isLocked = isObjectLocked(audience);
                    const lockedBy = objectIsLockedBy(audience);
                    const dateString = generateDateStringForTables(audience.lastModified);
                    const expanded = expandedAudience?._id === audience._id;
                    return (
                        <>
                            <TableRowWrapper key={audience._id}>
                                <>
                                    {/* AUDIENCE TITLE TABLE CELL */}
                                    <WidthTableCell {...GroupAudiencesTableSizes.name}>
                                        <AudienceTitleContainer onClick={() => expandAudience(audience)}>
                                            <TruncatedText>{audience.name || EMPTY_WORD_STRING}</TruncatedText>
                                            {isLocked && renderLockIcon(lockedBy)}
                                        </AudienceTitleContainer>
                                    </WidthTableCell>

                                    {/* LAST MODIFIED TABLE CELL */}
                                    <WidthTableCell {...GroupAudiencesTableSizes.lastModified}>
                                        <LastModified>
                                            <span>{dateString}</span>
                                            <Tooltip title={audience?.modifiedByUser?.name || ''} placement="right-start">
                                                <img src={audience?.modifiedByUser?.icon || icons.avatarIcon} alt="" />
                                            </Tooltip>
                                        </LastModified>
                                    </WidthTableCell>
                                </>
                                {/* ACTIONS TABLE CELL */}
                                <WidthTableCell {...GroupAudiencesTableSizes.actions}>
                                    <ObjectActions
                                        actions={[tableActions.PURGE, tableActions.INFO, tableActions.EDIT, tableActions.REMOVE]}
                                        withArrow
                                        open={expanded}
                                        onArrowToggle={() => {
                                            expandAudience(audience);
                                        }}
                                        onPurge={() => {
                                            purgeCdnForAudience(audience);
                                        }}
                                        onInfoClick={() => {
                                            setAudienceGuide(audience);
                                        }}
                                        onEdit={() => {
                                            if (unsavedChanges) return renderAlertUnsavedChanges();
                                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.AUDIENCES), {
                                                state: { audienceId: audience._id }
                                            });
                                        }}
                                        onRemove={() => {
                                            if (isLocked) return renderLockedWarningAlert(lockedBy);
                                            if (unsavedChanges) return renderAlertUnsavedChanges();
                                            handleDeleteClick(audience._id);
                                        }}
                                        tooltipTexts={{
                                            arrowOpen: 'target_groups_audiences_icon_arrow_show_values',
                                            arrowClose: 'target_groups_audiences_icon_arrow_hide_values',
                                            purge: 'target_groups_audiences_icon_value_purge',
                                            info: 'target_groups_audiences_icon_value_info',
                                            edit: 'target_groups_audiences_icon_edit',
                                            delete: 'target_groups_audiences_icon_remove'
                                        }}
                                    />
                                </WidthTableCell>
                            </TableRowWrapper>

                            {/* AUDIENCES TABLE ROW */}
                            {expanded && (
                                <>
                                    {/* SPACE BETWEEN ROWS */}
                                    <TableRow style={{ height: '8px' }} />
                                    <TableRowWrapper>
                                        <WidthTableCell style={{ padding: 0 }} colSpan={3} $um={'px'}>
                                            {viewType === 'GRID'
                                                ? renderAudienceGridView(expandedAudience)
                                                : renderAudienceListView(expandedAudience)}
                                        </WidthTableCell>
                                    </TableRowWrapper>
                                </>
                            )}

                            {/* SPACE BETWEEN ROWS */}
                            <TableRow style={{ height: '24px' }} />
                        </>
                    );
                })}
                <TableRow
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        if (unsavedChanges) return renderAlertUnsavedChanges();
                        setOpenNewResourceDialog(true);
                    }}
                >
                    <TableCell colSpan={3}>
                        <AddElementCell>
                            {`Add Audience`}
                            {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, `target_groups_audiences_icon_add`)}
                        </AddElementCell>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    };

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    return (
        <>
            {audiencesError && renderError(audiencesError)}
            <AudiencesWrapper $isList={viewType === 'LIST'}>
                {!loadingGroup && <GenericTable columns={[]} body={buildTableRows()} />}
            </AudiencesWrapper>

            <NewAudience
                open={openNewAudienceDialog}
                onSave={(newAudience) => {
                    handleCheckBeforeSave(newAudience);
                    setOpenNewResourceDialog(false);
                }}
                onClose={() => {
                    setOpenNewAudienceDialog(false);
                    setCreatingAudience(false);
                }}
                withCreateNewOption
            />
            <TargetingGuide
                open={!!audienceGuide}
                audience={audienceGuide}
                onClose={() => {
                    setAudienceGuide(undefined);
                }}
            />

            <CreateResourceDialog
                open={openNewResourceDialog}
                onClose={() => {
                    setOpenNewResourceDialog(false);
                }}
                title={'Audience'}
                handleCreateNewResourceClick={() => {
                    setOpenNewAudienceDialog(true);
                    setCreatingAudience(true);
                }}
                handleSelectExistingClick={() => {
                    setOpenExistingDialog(true);
                }}
                withSelectExisting
                withoutSelectTemplate
                historyUrl={''}
            />

            <UseExistingDialog
                open={openExistingDialog}
                onClose={() => {
                    setOpenExistingDialog(false);
                }}
                existingItemType={EXISTING_ITEMS.AUDIENCE}
                itemIdsToExclude={groupAudiences.map((audience) => audience._id)}
                onSave={(id) => {
                    const audience = allAudiences.find((audience) => audience._id === id);
                    handleCheckBeforeSave(audience);
                    setOpenNewResourceDialog(false);
                }}
            />
        </>
    );
};
