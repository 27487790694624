import React, { useEffect, useRef, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '../../Buttons/Button/Button';
import { StyledSVGInline, TruncatedText } from '../../../style/styled-components/reusable.css';
import icons from '../../../style';
import SelectSingle from '../Select/SelectSingle';
import SelectMultiple from '../Select/SelectMultiple';
import DragAndDrop from '../../../HOCs/DragAndDrop/DragAndDrop';
import { Fade, Typography } from '@material-ui/core';
import { CSSProperties } from 'styled-components';
import { DIALOG_NAMES, DIALOG_TITLES_MAP, dialogAlert, getHelperText } from '../../../utils/fnDialogs';
import SVGInline from 'react-inlinesvg';
import { ChromePicker, RGBColor } from 'react-color';
import { alphaHexToRGBA, alphaToHex } from '../../../utils/fnColor';
import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../Tooltips/Tooltips';
import TranslationTooltip from '../TranslationTooltip/TranslationTooltip';
import {
    AcceptedWrapper,
    ButtonStyle,
    CheckboxContainer,
    ColorBox,
    ColorFieldLabel,
    ColorPickerContainer,
    ColorPickerIconContainer,
    ContainerImagePreview,
    DialogTitle,
    DialogTitleActions,
    DialogTitleContentWrapper,
    DialogWrapper,
    ErrorLabel,
    FileField,
    FileFieldContainer,
    FileItem,
    FileNameUnderSelectionWrapper,
    HelpIconWrapper,
    ImagePreview,
    ImagePreviewIcon,
    InputLabelWithIconWrapper,
    LabelWrapper,
    MessageIconWrapper,
    RadioButtonWrapper,
    RadioCheckBox,
    RadioChecked,
    RadioLabel,
    RadioText,
    StyledDialog,
    StyledDialogScrollContent,
    SubtitleWrapper,
    TextInput,
    ToggleCheckBox,
    ToggleContainer,
    ToggleLabel,
    ToggleSlider,
    ToggleText,
    WarningsWrapper
} from './GenericDialog.css';
import AddFileDialog from './FileDialogs/AddFileDialog';
import FileManagerDialog from './FileDialogs/FileManagerDialog';
import OpenUrlDialog from './FileDialogs/OpenUrlDialog';
import HelpIcon, { CircleSlugOption } from '../HelpIcon/HelpIcon';
import { renderLockedWarningAlert } from '../../../utils/fnLockingSystem';
import { FilePreview } from '../../FileManager/Dialogs/FilePreview';
import { unsetRetryFunction } from '../../../hooks/useBlockNavigation';
import { iconType } from '../../../assets/images/icons';
import { WarningLabel, WarningLabelsWrapper } from '../../TargetGroups/GroupEdit.css';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppSelector } from '../../../hooks/redux';
import FileManagerClient from '../../../utils/api/FileManagerClient/FileManagerClient';
import { isOpenFileUrl } from '../../../utils/fnUrl';
import _ from 'lodash';
import { videoExtensions } from '../../FileManager/FileManager';
import { FileIconContainer } from '../../FileManager/FileManager.css';
import { shouldScrollVertical } from '../../../utils/fnScroll';

export type DialogProps = {
    title: string;
    subtitle?: string;
    type: DialogTypes;
    text?: string;
    onClose?: any;
    onConfirm?: any;
    onOk?: any;
    actionButtons?: DialogButton[];
    successIcon?: boolean;
    failIcon?: boolean;
    customIcon?: iconType;
    warningIcon?: boolean;
    style?: CSSProperties;
    buttonsInfo?: {
        noButton: {
            label: string;
            type?: 'BLUE' | 'RED' | 'PINK' | 'DEFAULT';
        };
        confirmButton: {
            label: string;
            type?: 'BLUE' | 'RED' | 'PINK' | 'DEFAULT';
        };
    };
    confirmReversed?: boolean;
    isEmpty?: boolean;
    dataCy?: string;
    withTitleTooltip?: boolean;
    withoutHelpIcon?: boolean; // used for toggling the help icon for dialogs if needed
    circlesSlugOptions?: CircleSlugOption;
    onConfirmClose?: (arg?: boolean) => void; // used for confirm dialog close icon
    warnings?: {
        icon: iconType;
        critical?: boolean;
        onClick?: () => void;
    }[];
};

export type DialogTextFieldProps = {
    className?: string;
    value: string;
    error?: string | boolean;
    label?: string;
    placeholder?: string;
    type?: string;
    InputProps?: any;
    isDisabled?: boolean;
    onChange: any;
    onKeyPress?: any;
    optional?: boolean;
    maxLength?: number;
    toolTipText?: string;
    style?: CSSProperties;
    onBlur?: () => void;
    onClick?: (e?: any) => void;
    onPaste?: (e?: any) => void;
    withTranslations?: boolean;
    inputRef?: any;
    dataCy?: string;
    inputProps?: any;
    isVisualEditor?: boolean;
    whiteWhileDisabled?: boolean;
    helperText?: string;
    multiline?: boolean;
    rows?: number;
    minRows?: number;
    maxRows?: number;
    noLabel?: boolean;
    withTopMargin?: boolean;
};

export type DialogDropdownProps = {
    noLabel?: boolean;
    labelText?: string;
    onChange: any;
    placeholder: string;
    options: any[];
    value: any;
    error?: string;
    clearable?: boolean;
    up?: boolean;
    isDisabled?: boolean;
    optional?: boolean;
    toolTipText?: string;
    toolTipHTML?: JSX.Element;
    openOnClick?: boolean;
    labeledSelect?: boolean;
    unclickableIndexes?: number[];
    onClick?: any;
    noError?: boolean;
    notSorted?: boolean;
    newOption?: DialogDropdownNewOption;
    adornmentStart?: string | JSX.Element;
    dataCy?: string;
    withLoadingIndicator?: boolean;
    withTopMargin?: boolean;
};

export type DialogDropdownNewOption = {
    name: string;
    onClick: () => void;
};

export type DialogDropdownSearchableProps = DialogDropdownProps & {
    menuIsOpen?: boolean;
    searchable?: boolean;
    isMulti?: boolean;
};
export type DialogDropdownSingleProps = DialogDropdownProps & {};

export type DialogDropdownMultipleProps = DialogDropdownProps & {
    allowSelectAll?: boolean;
    maxLength?: number;
};

export type DialogCheckboxProps = {
    onClick: any;
    text: string | JSX.Element;
    value: boolean;
    active: boolean;
    position?: 'left' | 'center' | 'right';
    tooltipText?: string;
    tooltipPosition?: tooltipPositions;
    disabled?: boolean;
    onTextClick?: () => void;
    reversed?: boolean;
    whiteTheme?: boolean;
    capitalizeText?: boolean;
};

export type DialogRadioProps = {
    onClick: () => void;
    text: string | JSX.Element;
    active: boolean;
    disabled?: boolean;
};

export type DialogToggleProps = {
    checked: boolean;
    toggleCallback: () => void;
    text: string | JSX.Element;
    disabled?: boolean;
    tooltipText?: string;
    dataCy?: string;
};

export type DialogColorFieldProps = {
    width: string;
    height: string;
    onColorPicked: (value: string) => any;
    value?: string;
    showColorValue?: boolean;
    label?: string;
    applyAlpha?: boolean;
};

export type ImageInfoType = {
    previewImage: boolean;
    width: number;
    height: number;
    um: UMTypes;
};

export type DialogFileFieldProps = {
    fieldKey: string;
    multiple?: boolean;
    accepts?: string[];
    imageInfo?: ImageInfoType;
    localFileCallBack?: (value: any) => void;
    localFileErrorCallBack?: () => void;
    fileManagerCallback?: any;
    openUrlCallback?: any;
    onChange?: () => void;
    customLabel?: string;
    alwaysShowLabel?: boolean;
    additionalText?: any;
    error?: string;
    preview?: string | ArrayBuffer;
    hideAccepted?: boolean;
    customAddIcon?: any;
    droppedFile?: any;
    localFileOnly?: boolean;
    dataCy?: string;
    handleRemoveFile?: () => void;
    locked?: boolean;
    lockedBy?: string;
    withoutPreview?: boolean;
    withoutFileManager?: boolean;
    withTitleTooltip?: boolean;
    projectId?: string;
};

export type DialogButton = {
    label: string;
    type: 'BLUE' | 'RED' | 'PINK' | 'DEFAULT';
    onClick: any;
    loading?: boolean;
    closeOnClick?: boolean;
    disabled?: boolean;
};

export enum DialogTypes {
    Form = 'FORM',
    Alert = 'ALERT',
    LockedAlert = 'LOCKED_ALERT',
    Confirm = 'CONFIRM',
    Flat = 'FLAT',
    Audience = 'AUDIENCE',
    Template = 'TEMPLATE',
    Resource = 'RESOURCE',
    FileManager = 'FILE_MANAGER',
    FilePreview = 'FILE_PREVIEW',
    PageStyle = 'PAGE_STYLE',
    DisplayCondition = 'DISPLAY_CONDITION',
    TimePicker = 'TIME_PICKER',
    TimePickerRange = 'TIME_PICKER_RANGE',
    DatePicker = 'DATE_PICKER',
    DatePickerRange = 'DATE_PICKER_RANGE',
    MoreInfo = 'MORE_INFO'
}

export type UMTypes = 'px' | '%' | 'vh' | 'vw';

export const DialogTextField: React.FC<DialogTextFieldProps> = ({
    value,
    error,
    label,
    placeholder,
    onChange,
    onKeyPress,
    type,
    InputProps,
    isDisabled,
    optional,
    maxLength,
    toolTipText,
    style,
    onBlur,
    onClick,
    onPaste,
    withTranslations,
    inputRef,
    dataCy,
    inputProps,
    isVisualEditor,
    whiteWhileDisabled,
    helperText,
    multiline,
    rows,
    minRows,
    maxRows,
    noLabel,
    withTopMargin
}) => {
    const translationAdornment = withTranslations ? (
        <TranslationTooltip
            translationKey={value}
            showNewTranslationKey
            isVisualEditor={isVisualEditor}
            // the way params are passed on onChage method should be changed in the future
            handleKeyChange={(val) => onChange({ target: { value: val } })}
            isTextField
        />
    ) : undefined;
    return (
        <TextInput
            inputRef={inputRef}
            disabled={isDisabled}
            label={
                toolTipText ? (
                    <InputLabelWithIconWrapper>
                        {label}
                        {renderTooltip(<SVGInline src={icons.infoIcon} />, tooltipTypes.TEXT, toolTipText || '', tooltipPositions.TOP)}
                    </InputLabelWithIconWrapper>
                ) : (
                    label
                )
            }
            $withTopMargin={withTopMargin}
            noLabel={noLabel}
            autoComplete="off"
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            error={!!error}
            value={value}
            rows={rows}
            minRows={minRows}
            maxRows={maxRows}
            multiline={multiline}
            type={type || 'text'}
            InputProps={{ endAdornment: translationAdornment, ...InputProps } || {}}
            style={style}
            helperText={helperText || getHelperText(error, optional)}
            data-cy={dataCy}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                evt.preventDefault();
                if (onChange) {
                    onChange(evt);
                }
            }}
            onKeyPress={(evt: React.ChangeEvent<HTMLInputElement>) => {
                onKeyPress?.(evt);
            }}
            inputProps={{
                maxLength,
                ...inputProps
            }}
            onBlur={(evt: React.FocusEvent<HTMLInputElement>) => {
                evt.preventDefault();
                if (onBlur) {
                    onBlur();
                }
            }}
            onClick={(evt: any) => {
                if (onClick) {
                    onClick(evt);
                }
            }}
            onPaste={(evt: any) => {
                onPaste?.(evt);
            }}
            whiteWhileDisabled={whiteWhileDisabled}
        />
    );
};

export const DialogDropdownSingle: React.FC<DialogDropdownSingleProps> = ({
    noLabel,
    onChange,
    placeholder,
    options,
    value,
    error,
    up,
    labelText,
    isDisabled,
    optional,
    toolTipText,
    toolTipHTML,
    openOnClick,
    labeledSelect,
    unclickableIndexes,
    onClick,
    clearable,
    noError,
    notSorted,
    newOption,
    dataCy,
    adornmentStart,
    withLoadingIndicator,
    withTopMargin
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const timeoutRef = useRef<number | null>(null);

    const handleMouseLeave = () => {
        timeoutRef.current = window.setTimeout(() => {
            setTooltipOpen(false);
        }, 250);
    };

    const handleMouseEnter = () => {
        setTooltipOpen(true);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    return (
        <SelectSingle
            isDisabled={isDisabled}
            noLabel={noLabel}
            labelText={
                toolTipText || toolTipHTML ? (
                    <InputLabelWithIconWrapper>
                        {labelText}
                        {renderTooltip(
                            <SVGInline onMouseEnter={handleMouseEnter} src={icons.infoIcon} />,
                            toolTipText ? tooltipTypes.TEXT : tooltipTypes.HTML,
                            toolTipText ? (
                                toolTipText || ''
                            ) : (
                                <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onBlur={() => setTooltipOpen(false)}>
                                    {toolTipHTML}
                                </div>
                            ),
                            tooltipPositions.TOP,
                            false,
                            false,
                            true,
                            tooltipOpen
                        )}
                    </InputLabelWithIconWrapper>
                ) : (
                    labelText
                )
            }
            placeholder={placeholder}
            value={value}
            error={error}
            options={options}
            up={up}
            onChange={(evt: any) => {
                if (onChange) {
                    onChange(evt || { value: '', label: '' });
                }
            }}
            onClick={(evt: any) => {
                if (onClick) {
                    onClick(evt);
                }
            }}
            onFocus={() => setTooltipOpen(false)}
            onMouseLeave={handleMouseLeave}
            optional={optional}
            openOnClick={openOnClick}
            unclickableIndexes={labeledSelect ? unclickableIndexes : undefined}
            clearable={clearable}
            noError={noError}
            notSorted={notSorted}
            newOption={!!newOption ? newOption : undefined}
            dataCy={dataCy}
            withTopMargin={withTopMargin}
            adornmentStart={adornmentStart}
            withLoadingIndicator={withLoadingIndicator}
        />
    );
};

export const DialogDropdownMultiple: React.FC<DialogDropdownMultipleProps> = ({
    onChange,
    placeholder,
    options,
    value,
    error,
    noLabel,
    up,
    labelText,
    allowSelectAll,
    isDisabled,
    optional,
    toolTipText,
    openOnClick,
    labeledSelect,
    unclickableIndexes,
    noError,
    maxLength,
    newOption,
    adornmentStart,
    notSorted,
    withLoadingIndicator,
    withTopMargin
}) => {
    return (
        <SelectMultiple
            isDisabled={isDisabled}
            noLabel={noLabel}
            labelText={
                toolTipText ? (
                    <InputLabelWithIconWrapper>
                        {labelText}
                        {renderTooltip(<SVGInline src={icons.infoIcon} />, tooltipTypes.TEXT, toolTipText || '', tooltipPositions.TOP)}
                    </InputLabelWithIconWrapper>
                ) : (
                    labelText
                )
            }
            placeholder={placeholder}
            value={value}
            error={error}
            options={options}
            up={up}
            allowSelectAll={allowSelectAll}
            onChange={(evt: any) => {
                if (onChange) {
                    onChange(evt);
                }
            }}
            optional={optional}
            openOnClick={openOnClick}
            unclickableIndexes={labeledSelect ? unclickableIndexes : undefined}
            noError={noError}
            maxLength={maxLength}
            newOption={newOption}
            adornmentStart={adornmentStart}
            notSorted={notSorted}
            withTopMargin={withTopMargin}
            withLoadingIndicator={withLoadingIndicator}
        />
    );
};

export const DialogCheckbox: React.FC<DialogCheckboxProps> = ({
    active,
    onClick,
    text,
    position,
    tooltipText,
    tooltipPosition,
    disabled,
    onTextClick,
    reversed,
    whiteTheme,
    capitalizeText
}) => {
    // Making sure the icons are loaded in, to prevent glitchy checkbox checking
    const { checkboxIcon, checkboxActiveIcon, checkboxLightIcon, checkboxActiveLightIcon } = icons;

    const handleOnClick = (e: any) => {
        e.stopPropagation();
        !disabled && onClick();
    };

    const handleOnTextClick = (e: any) => {
        if (onTextClick) {
            e.stopPropagation();
            !disabled && onTextClick();
        }
    };

    const iconToShow = !active
        ? whiteTheme
            ? checkboxLightIcon
            : checkboxIcon
        : whiteTheme
        ? checkboxActiveLightIcon
        : checkboxActiveIcon;

    return tooltipText ? (
        renderTooltipWithKey(
            <CheckboxContainer
                $active={active}
                $center={position === 'center'}
                $reversed={reversed}
                $disabled={disabled}
                $whiteTheme={whiteTheme}
                $capitalizeText={capitalizeText}
                onClick={handleOnClick}
            >
                {reversed ? (
                    <>
                        <TruncatedText onClick={handleOnTextClick}>{text}</TruncatedText>
                        <div>
                            <StyledSVGInline src={iconToShow} />
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <StyledSVGInline src={iconToShow} />
                        </div>
                        <TruncatedText onClick={handleOnTextClick}>{text}</TruncatedText>
                    </>
                )}
            </CheckboxContainer>,
            tooltipText,
            tooltipPosition
        )
    ) : (
        <CheckboxContainer
            $active={active}
            $center={position === 'center'}
            $disabled={disabled}
            $reversed={reversed}
            $whiteTheme={whiteTheme}
            $capitalizeText={capitalizeText}
            onClick={handleOnClick}
        >
            {reversed ? (
                <>
                    <TruncatedText onClick={handleOnTextClick}>{text}</TruncatedText>
                    <div>
                        <StyledSVGInline src={iconToShow} />
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <StyledSVGInline src={iconToShow} />
                    </div>
                    <TruncatedText onClick={handleOnTextClick}>{text}</TruncatedText>
                </>
            )}
        </CheckboxContainer>
    );
};

export const DialogRadioButton: React.FC<DialogRadioProps> = ({ active, onClick, text, disabled }) => {
    return (
        <RadioButtonWrapper $disabled={disabled}>
            <RadioLabel>
                <RadioCheckBox type={'radio'} checked={active} onClick={() => (disabled ? {} : onClick())} readOnly />
                <RadioChecked />
            </RadioLabel>
            <RadioText>{text}</RadioText>
        </RadioButtonWrapper>
    );
};

export const DialogToggleButton: React.FC<DialogToggleProps> = ({ checked, toggleCallback, text, disabled, tooltipText, dataCy }) => {
    const toggle = (
        <ToggleContainer $disabled={disabled}>
            <ToggleLabel data-cy={dataCy}>
                <ToggleCheckBox
                    type={'checkbox'}
                    checked={checked}
                    onChange={() => {
                        toggleCallback();
                    }}
                    disabled={disabled}
                />
                <ToggleSlider />
            </ToggleLabel>
            <ToggleText>{text}</ToggleText>
        </ToggleContainer>
    );
    return tooltipText ? renderTooltipWithKey(toggle, tooltipText, tooltipPositions.TOP, 'toggle_button') : toggle;
};

export const DialogColorField: React.FC<DialogColorFieldProps> = ({
    width,
    height,
    onColorPicked,
    value,
    showColorValue,
    label,
    applyAlpha
}) => {
    const [color, setColor] = useState<{ rgba?: RGBColor; hex?: string }>({});
    const [pickerOpen, setPickerOpen] = useState(false);

    const pickerWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!value) return;
        if (applyAlpha) {
            if (value.length === 6) {
                value = '#FF' + value;
            } else if (value.length === 7 && value.indexOf('#') >= 0) {
                value = '#FF' + value.split('#')[1];
            }
            setColor({ rgba: alphaHexToRGBA(value), hex: value });
        } else {
            if (value.indexOf('#') < 0) {
                value = `#${value}`;
            }
            setColor({ rgba: alphaHexToRGBA(value), hex: value });
        }
    }, [value]);

    useEffect(() => {
        const handleOutsideClick = (evt: any) => {
            if (pickerWrapperRef.current && !pickerWrapperRef.current.contains(evt.target)) {
                setPickerOpen(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [pickerWrapperRef]);

    const boxColor = Object.keys(color.rgba || {}).length
        ? applyAlpha
            ? `rgba(${color.rgba?.r},${color.rgba?.g},${color.rgba?.b},${color.rgba?.a})`
            : `rgb(${color.rgba?.r},${color.rgba?.g},${color.rgba?.b})`
        : '';

    return (
        <ColorPickerContainer $open={pickerOpen}>
            <div ref={pickerWrapperRef}>
                <ChromePicker
                    color={color.rgba}
                    disableAlpha={!applyAlpha}
                    onChange={(newColor) => {
                        let hex = '';
                        if (applyAlpha) {
                            hex = ('#' + alphaToHex(newColor.rgb.a) + newColor.hex.slice(1)).toUpperCase();
                        } else {
                            hex = newColor.hex.toUpperCase();
                        }

                        setColor((color) => {
                            return {
                                ...color,
                                rgba: newColor.rgb,
                                hex
                            };
                        });
                        onColorPicked(hex);
                    }}
                />
            </div>
            {label && <ColorFieldLabel>{label}</ColorFieldLabel>}
            <ColorBox onClick={() => setPickerOpen(!pickerOpen)} $width={width} $height={height} $color={boxColor}>
                <ColorPickerIconContainer>
                    <StyledSVGInline src={icons.configuratorIcon} />
                </ColorPickerIconContainer>
            </ColorBox>
            {showColorValue && <ColorFieldLabel $addOpacity> {color.hex}</ColorFieldLabel>}
        </ColorPickerContainer>
    );
};

export const DialogFileField: React.FC<DialogFileFieldProps> = ({
    fieldKey,
    multiple,
    accepts,
    imageInfo,
    localFileCallBack,
    localFileErrorCallBack,
    fileManagerCallback,
    openUrlCallback,
    customLabel,
    alwaysShowLabel,
    additionalText,
    error,
    preview,
    hideAccepted,
    customAddIcon,
    droppedFile,
    localFileOnly,
    dataCy,
    handleRemoveFile = () => {},
    locked,
    lockedBy,
    withoutPreview,
    withoutFileManager,
    projectId
}) => {
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { config } = useAppSelector((state) => state.config);
    const [files, setFiles] = useState<File[]>([]);
    const inputRef = React.createRef<HTMLInputElement>();
    const [previewFile, setPreviewFile] = useState<string | ArrayBuffer | null>(preview || null);
    const [openNewFileDialog, setOpenNewFileDialog] = useState<boolean>(false);
    const [openFileManagerDialog, setOpenFileManagerDialog] = useState<boolean>(false);
    const [openUrlDialog, setOpenUrlDialog] = useState<boolean>(false);
    const [openFilePreview, setOpenFilePreview] = useState<boolean>(false);

    const acceptedExtensions = accepts?.length ? accepts : ['.jpg', '.png'];
    const handleBrowseClick = () => {
        let input = inputRef.current;
        if (input) {
            input.click();
        }
    };

    useEffect(() => {
        if (!FileManagerClient.projectId) {
            FileManagerClient.projectId = projectId || activeProjectId || '';
        }
    }, [activeProjectId, projectId]);

    useEffect(() => {
        setPreviewFile(preview || null);
        // If the preview is deleted, we also delete the files
        if (!preview) setFiles([]);
    }, [preview]);

    useEffect(() => {
        if (droppedFile) {
            setFiles(droppedFile);
        }
    }, [droppedFile]);

    useEffect(() => {
        if (imageInfo?.previewImage) {
            if (files[0]) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreviewFile(reader.result);
                };
                reader.readAsDataURL(files[0]);
            }
        }
    }, [files]);

    const handleRemoveFileClick = (evt: React.MouseEvent<HTMLDivElement>, index: number) => {
        evt.preventDefault();
        handleRemoveFile();

        localFileCallBack && localFileCallBack(null);

        setFiles((files) => {
            const newFiles = [...files];
            if (!files[index]) return newFiles;
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    useEffect(() => {
        if (!files.length) return;

        if (acceptedExtensions.includes('.json')) {
            const fReader = new FileReader();
            fReader.onloadend = () => {
                try {
                    if (typeof fReader.result === 'string') {
                        const data = JSON.parse(fReader.result);
                        localFileCallBack && localFileCallBack(data);
                    }
                } catch (err) {
                    localFileErrorCallBack?.();
                    console.error(err);
                }
            };

            fReader.readAsText(files[0]);
        } else {
            localFileCallBack && (multiple ? localFileCallBack(files) : localFileCallBack(files[0]));
        }
    }, [files]);

    const handleFileInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault();
        const newFiles = evt.target.files;
        if (newFiles) {
            let filesToAdd = Array.from(newFiles);
            if (!multiple) {
                return setFiles([filesToAdd[0]]);
            }
            setFiles([...files, ...Array.from(filesToAdd)]);
        }
    };
    const calculateFileNameToShow = (): string | undefined => {
        if (!previewFile || typeof previewFile !== 'string') return undefined;
        const isOpenUrl = isOpenFileUrl(config.imgixBaseUrl, previewFile);
        if (isOpenUrl) {
            return previewFile;
        }
        const folderPath = previewFile?.replace(config.imgixBaseUrl, '').split('?')[0] || '';
        const fileName = _.last(folderPath.split('/')) || '';
        return fileName;
    };
    const renderFileNameUnderSelection = () => {
        const fileName = calculateFileNameToShow();
        if (!fileName) return null;

        return (
            <FileNameUnderSelectionWrapper>
                {renderTooltip(<TruncatedText>{fileName}</TruncatedText>, tooltipTypes.HTML, fileName)}
            </FileNameUnderSelectionWrapper>
        );
    };

    const notSupportedExtensionAlert = () => {
        const values = {
            title: 'File not supported!',
            subtitle: `Supported files are: ${acceptedExtensions.map((ext) => ext.replace('.', '')).join(', ')}`
        };
        dialogAlert('', false, values);
    };

    const fileNameUnderSelectionComponent = renderFileNameUnderSelection();
    const calculateIsVideo = (url: string): boolean => {
        const folderPath = url?.replace(config.imgixBaseUrl, '').split('?')[0] || '';
        const extension = _.last(folderPath.split('.')) || '';
        return videoExtensions.includes(extension);
    };
    const isVideo = typeof previewFile === 'string' && calculateIsVideo(previewFile);
    return (
        <>
            <FileFieldContainer
                $fullHeight={false}
                onClick={() => {
                    if (locked) {
                        return renderLockedWarningAlert(lockedBy);
                    }
                    if ((files?.length || previewFile) && imageInfo?.previewImage) return;
                    if (localFileOnly) return handleBrowseClick();
                    setOpenNewFileDialog(true);
                }}
            >
                {alwaysShowLabel || (!files?.length && !previewFile) ? (
                    <LabelWrapper>{customLabel ?? `Choose ${fieldKey || 'text'}`}</LabelWrapper>
                ) : null}

                {!files?.length && !previewFile ? (
                    <DragAndDrop
                        imageInfo={imageInfo}
                        accepted={acceptedExtensions}
                        onDrop={(newFiles: File[]) => {
                            if (newFiles.length) {
                                if (!multiple) {
                                    return setFiles([newFiles[0]]);
                                }
                                setFiles([...files, ...newFiles]);
                            }
                        }}
                        validationError={error !== undefined}
                        errorCallback={localFileErrorCallBack}
                    >
                        <FileField>
                            <StyledSVGInline style={{ zIndex: 0 }} src={customAddIcon || icons.browseIcon} />
                            {additionalText && additionalText}
                            <input
                                id="file-upload-input"
                                type="file"
                                accept={acceptedExtensions.join(',')}
                                onChange={handleFileInputChange}
                                multiple={multiple === true}
                                ref={inputRef}
                                data-cy={dataCy}
                            />
                        </FileField>
                    </DragAndDrop>
                ) : null}
                {(files?.length || previewFile) && imageInfo?.previewImage ? (
                    <ContainerImagePreview width={imageInfo.width} height={imageInfo.height} um={imageInfo.um} isVideo={isVideo}>
                        {isVideo ? (
                            <FileIconContainer>
                                <SVGInline src={icons.videoIcon} />
                            </FileIconContainer>
                        ) : (
                            <ImagePreview width={imageInfo.width} height={imageInfo.height} src={previewFile as string} />
                        )}

                        <ImagePreviewIcon>
                            {!withoutPreview && !withoutFileManager && (
                                <StyledSVGInline
                                    onClick={() => {
                                        setOpenFilePreview(true);
                                    }}
                                    src={icons.infoIcon}
                                />
                            )}
                            <StyledSVGInline
                                onClick={(e: any) => {
                                    if (locked) {
                                        e.stopPropagation();
                                        return renderLockedWarningAlert(lockedBy);
                                    }
                                    setPreviewFile(null);
                                    setFiles([]);
                                    localFileCallBack?.(null);
                                    fileManagerCallback?.('');
                                }}
                                src={icons.trashIcon}
                            />
                        </ImagePreviewIcon>
                    </ContainerImagePreview>
                ) : files?.length ? (
                    files.map((file: File, index) => {
                        let name = file.name;
                        if (name.length > 15) {
                            name = name.slice(0, 12) + '...' + name.split('.').pop();
                        }
                        return (
                            <FileItem key={`file_${index}`}>
                                <StyledSVGInline style={{ zIndex: 0 }} src={icons.paperclipIcon} />
                                <span>{name}</span>
                                <div onClick={(evt) => handleRemoveFileClick(evt, index)}>Remove</div>
                            </FileItem>
                        );
                    })
                ) : null}
                {!hideAccepted && (
                    <AcceptedWrapper $fullMargin={false}>
                        {fileNameUnderSelectionComponent ? (
                            fileNameUnderSelectionComponent
                        ) : (
                            <LabelWrapper>*{acceptedExtensions.join(', ').replaceAll('.', '').toUpperCase()}</LabelWrapper>
                        )}

                        <ErrorLabel error={error !== undefined}>{error ? error : ''}</ErrorLabel>
                    </AcceptedWrapper>
                )}
            </FileFieldContainer>
            <AddFileDialog
                open={openNewFileDialog}
                onClose={() => {
                    setOpenNewFileDialog(false);
                }}
                handleFileManagerClick={() => {
                    if (withoutFileManager) {
                        handleBrowseClick();
                        setOpenNewFileDialog(false);
                    } else setOpenFileManagerDialog(true);
                }}
                handleOpenURLClick={() => {
                    setOpenUrlDialog(true);
                    setOpenNewFileDialog(false);
                }}
            />
            <FileManagerDialog
                open={openFileManagerDialog}
                onClose={() => {
                    setOpenNewFileDialog(false);
                    setOpenFileManagerDialog(false);
                }}
                onFileClick={(url: string) => {
                    const fileExtension = _.last(url?.split('.')) || '';
                    const isSupported = acceptedExtensions.includes(`.${fileExtension}`);
                    if (!isSupported) return notSupportedExtensionAlert();
                    fileManagerCallback?.(url);
                    setOpenNewFileDialog(false);
                    setOpenFileManagerDialog(false);
                }}
            />
            <OpenUrlDialog
                open={openUrlDialog}
                onClose={() => {
                    setOpenUrlDialog(false);
                    setOpenNewFileDialog(false);
                }}
                onSave={(url: string) => {
                    openUrlCallback?.(url);
                    setOpenUrlDialog(false);
                    setOpenNewFileDialog(false);
                }}
            />

            <FilePreview open={openFilePreview} onClose={() => setOpenFilePreview(false)} file={previewFile as string} fullUrl />
        </>
    );
};

const GenericDialog: React.FC<DialogProps> = ({
    type,
    text,
    title,
    subtitle,
    onClose,
    actionButtons,
    children,
    onConfirm,
    onOk,
    successIcon,
    failIcon,
    warningIcon,
    customIcon,
    style,
    buttonsInfo,
    confirmReversed,
    isEmpty,
    dataCy,
    withTitleTooltip,
    withoutHelpIcon,
    circlesSlugOptions,
    onConfirmClose,
    warnings
}) => {
    const [isOpen, setIsOpen] = useState(true);

    const dialogRef = useRef<any>(null);
    const dialogContentRef = useRef<HTMLDivElement>(null);
    // needed to apply overflow on dialog when the dialog is really scrollable,
    // this way we can have the dropdowns flow outside the dialogs when there is enough space
    const [isDialogContentOverflowing, setIsDialogContentOverflowing] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            dialogRef.current?.focus();

            const container = dialogContentRef.current;
            if (container) {
                const checkOverflow = () => {
                    // 212 px is the height of the title + dialog actions
                    setIsDialogContentOverflowing(
                        Math.abs(container.offsetHeight - window.innerHeight * 0.9 + (withActions ? 212 : 104)) < 5 // 5 pixel "error" margin
                    );
                };
                checkOverflow();
                // check changes (i.e: toggling apply for all)
                const mutationObserver = new MutationObserver(checkOverflow);
                mutationObserver.observe(container, { childList: true, subtree: true });
                return () => mutationObserver.disconnect();
            }
        }, 0);
    }, []);

    const handleClose = (evt: any) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (onClose) {
            onClose(evt);
        }
        defaultClose();
    };

    const handleConfirm = (evt: any) => {
        evt.preventDefault();
        if (onConfirm) {
            onConfirm();
        }
        defaultClose();
    };

    const handleOk = (evt: any) => {
        evt.preventDefault();
        if (onOk) {
            onOk();
        }
        handleClose(evt);
    };

    const defaultClose = (isClosePressed?: boolean) => {
        onConfirmClose?.(isClosePressed);
        setIsOpen(false);
    };

    const renderActions = () => {
        let actions: JSX.Element[] = [];
        const okButton = (
            <Button
                key={`${title}_dialog_button_ok`}
                label={'OK'}
                style={ButtonStyle}
                type={'BLUE'}
                onClick={handleOk}
                dataCy={'dialog-ok-button'}
            />
        );

        const noButton = (
            <Button
                key={`${title}_dialog_button_no`}
                label={!!buttonsInfo?.noButton ? buttonsInfo?.noButton?.label : 'NO'}
                style={ButtonStyle}
                type={buttonsInfo?.noButton?.type || (!confirmReversed ? 'BLUE' : 'DEFAULT')}
                onClick={handleClose}
                dataCy={'dialog-no-button'}
            />
        );
        const confirmButton = (
            <Button
                key={`${title}_dialog_button_confirm`}
                label={!!buttonsInfo?.confirmButton ? buttonsInfo?.confirmButton?.label : 'YES'}
                style={ButtonStyle}
                type={buttonsInfo?.confirmButton?.type || (!confirmReversed ? 'DEFAULT' : 'BLUE')}
                onClick={handleConfirm}
                dataCy={'dialog-confirm-button'}
            />
        );
        switch (type) {
            case DialogTypes.Alert:
            case DialogTypes.LockedAlert:
                actions.push(okButton);
                break;
            case DialogTypes.Form:
            case DialogTypes.Audience:
            case DialogTypes.PageStyle:
            case DialogTypes.DisplayCondition:
            case DialogTypes.TimePicker:
            case DialogTypes.TimePickerRange:
            case DialogTypes.DatePicker:
            case DialogTypes.DatePickerRange:
            case DialogTypes.MoreInfo:
                actions = actionButtons
                    ? actionButtons.map((button, index) => (
                          <Button
                              key={`${title}_dialog_button_action_${index}`}
                              dataCy={`dialog-action-button-${index}`}
                              label={button.label}
                              style={ButtonStyle}
                              type={button.type}
                              onClick={(evt: any) => {
                                  button.onClick?.(evt);
                                  button.closeOnClick && defaultClose();
                              }}
                              loading={button.loading}
                              isDisabled={button?.disabled}
                          />
                      ))
                    : [];
                break;
            case DialogTypes.Confirm:
                if (confirmReversed) {
                    actions.push(noButton, confirmButton);
                } else {
                    actions.push(confirmButton, noButton);
                }
                break;
            default:
                break;
        }
        const warningsToRender = warnings?.map((warning, index) => {
            return (
                <WarningLabelsWrapper key={`warning-${index}`}>
                    <WarningLabel
                        critical={warning.critical}
                        onClick={() => {
                            warning.onClick?.();
                        }}
                        withoutText
                    >
                        <SVGInline src={warning.icon} />
                    </WarningLabel>
                </WarningLabelsWrapper>
            );
        });
        return !actions.length ? (
            <></>
        ) : (
            <DialogActions>
                {!!warningsToRender?.length && <WarningsWrapper>{warningsToRender}</WarningsWrapper>}
                {actions}
            </DialogActions>
        );
    };

    const withActions: boolean =
        !!actionButtons?.length || [DialogTypes.Alert, DialogTypes.LockedAlert, DialogTypes.Confirm].includes(type);

    return (
        <StyledDialog
            open={isOpen}
            maxWidth={false}
            empty={isEmpty}
            TransitionComponent={DialogTransitionComponent}
            type={type}
            data-cy={dataCy}
            disableRestoreFocus
            onClose={(event, reason) => {
                if (reason === 'escapeKeyDown') {
                    type === DialogTypes.Confirm ? defaultClose(true) : handleClose(event);
                }
            }}
            ref={dialogRef}
            tabIndex={-1}
        >
            <DialogWrapper
                $withIcon={successIcon || failIcon || warningIcon || !!customIcon}
                style={style}
                $empty={isEmpty}
                $overflow={isDialogContentOverflowing}
                $withoutActions={!withActions}
            >
                <DialogTitle disableTypography>
                    {withTitleTooltip ? (
                        renderTooltip(
                            <DialogTitleContentWrapper>
                                <Typography component="h2" variant="h4">
                                    <TruncatedText>{title}</TruncatedText>
                                </Typography>
                            </DialogTitleContentWrapper>,
                            tooltipTypes.TEXT,
                            title,
                            tooltipPositions.TOP
                        )
                    ) : (
                        <DialogTitleContentWrapper>
                            <Typography component="h2" variant="h4">
                                <TruncatedText>{title}</TruncatedText>
                            </Typography>
                        </DialogTitleContentWrapper>
                    )}
                    <DialogTitleActions>
                        {!withoutHelpIcon && circlesSlugOptions && (
                            <HelpIconWrapper>
                                <HelpIcon circlesSlugOptions={circlesSlugOptions} inDialog />
                            </HelpIconWrapper>
                        )}
                        {!isEmpty &&
                            renderTooltipWithKey(
                                <StyledSVGInline
                                    src={icons.closeIcon}
                                    onClick={(evt) => {
                                        type === DialogTypes.Confirm ? defaultClose(true) : handleClose(evt);

                                        // When closing the unsaved changes dialog, if there was a retry function set,
                                        // unset it to prevent it being called when the showUnsaved becomes false again
                                        if (title === DIALOG_TITLES_MAP.get(DIALOG_NAMES.UNSAVED_CHANGES)?.title) unsetRetryFunction();
                                    }}
                                />,
                                'generic_icon_close'
                            )}
                    </DialogTitleActions>
                </DialogTitle>

                <StyledDialogScrollContent ref={dialogContentRef}>
                    {successIcon && (
                        <MessageIconWrapper>
                            <StyledSVGInline src={icons.successIcon} />
                        </MessageIconWrapper>
                    )}
                    {failIcon && (
                        <MessageIconWrapper>
                            <StyledSVGInline src={icons.failIcon} />
                        </MessageIconWrapper>
                    )}
                    {warningIcon && (
                        <MessageIconWrapper>
                            <StyledSVGInline src={icons.warningIcon} />
                        </MessageIconWrapper>
                    )}
                    {!!customIcon && (
                        <MessageIconWrapper>
                            <StyledSVGInline src={customIcon} />
                        </MessageIconWrapper>
                    )}
                    {text || subtitle ? (
                        <DialogContentText>
                            {subtitle && <SubtitleWrapper>{subtitle}</SubtitleWrapper>}
                            {text}
                        </DialogContentText>
                    ) : null}
                    {children}
                </StyledDialogScrollContent>
                {renderActions()}
            </DialogWrapper>
        </StyledDialog>
    );
};

export default GenericDialog;
const DialogTransitionComponent = (props: any) => <Fade {...props} />;
