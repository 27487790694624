import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { AddLogoIcon } from '../Items/EditorialView.css';
import { TranslationTooltipIconContainer } from '../common/TranslationTooltip/TranslationTooltip.css';
import { providerLogos } from './VisualElements/ProviderLogoCard';
import { EditorialCustomizeVisualContainer } from './EditorialItemCustomization/EditorialItemCustomization.css';
import { LoaderContainer } from '../common/Loader/Loader.css';
import { templates } from '../../types/Module';
import { ShowMoreIconWrapper } from '../common/ShowMoreIcon/ShowMoreIcon.css';
import React from 'react';

const theme: any = getTheme();

export const DraggableCardWrapper = styled.div<{
    background?: string;
    $search?: boolean;
    $extraMargin?: boolean;
    $isGalleryOrPreview?: boolean;
}>`
    position: relative;
    color: ${theme.palette.white.main};
    border-radius: 4px;
    ${({ $search }) => $search && 'min-height: 307px'}
    height: fit-content;
    width: 100%;
    background: ${({ background }) => (background ? background : theme.palette.black.main)};
    background-repeat: no-repeat;
    background-size: cover;

    ${({ $isGalleryOrPreview }) => !$isGalleryOrPreview && `  padding: 36px 0;`};
    ${({ $extraMargin }) => $extraMargin && `margin: 23px 0`};
    ${({ $search }) =>
        $search &&
        `${CardContainer} {
        height: 100%;
    }`}
`;

export const CardContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const AdvancedBackgroundGap = styled.div`
    height: 280px;
`;

export const CardDragIcon = styled.div<{ $moduleType: TemplateSizeKey; $dragging?: boolean; $disabled?: boolean }>`
    z-index: 1;
    width: 48px;
    // ${(props) => props.$disabled && `pointer-events: none`};

    svg {
        padding: 0 8px 0 16px;
    }

    ${(props) =>
        !props.$disabled
            ? css`
                  svg {
                      opacity: 0.7;
                      &:hover {
                          opacity: 1;
                      }
                      cursor: move;
                  }
              `
            : css`
                  svg {
                      opacity: 0.7;
                      cursor: not-allowed;
                  }
              `}

    height: ${({ $moduleType }) => {
        const height = parseInt((TEMPLATE_SIZES[$moduleType]?.height || TEMPLATE_SIZES['UNKNOWN']?.height).split('px')[0]);
        return `${height + 108}px`;
    }};
    display: flex;
    align-items: center;
`;

export const VisualEditorCardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const GalleryOrPreviewContentWrapper = styled.div`
    height: 764px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 36px;
    overflow: auto;
    box-sizing: border-box;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    margin-right: 16px;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

    ${LoaderContainer} {
        position: absolute;
    }
`;

export const GalleryCardsContainer = styled.div`
    position: relative;
`;

export const CardsContainer = styled.div<{ isGallery?: boolean }>`
    position: relative;
    display: flex;
    white-space: nowrap;
    padding: 16px 0 20px 0;
    margin-left: 24px;
    margin-right: 16px;
    overflow-y: hidden;
    overflow-x: auto;

    @-moz-document url-prefix() {
        scrollbar-color: ${theme.palette.primary.main} ${theme.palette.text.main};
        scrollbar-width: thin;
    }
    ::-webkit-scrollbar {
        height: 4px;
        background-color: ${theme.palette.text.main};
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.primary.main};
        border-radius: 4px;
    }
    ::-webkit-scrollbar-button {
        display: none;
    }
`;

export const StripeName = styled.div<{ color?: string }>`
    ${(props) => props.color && `color: ${props.color}`};
    width: 90%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    ${ShowMoreIconWrapper} {
        width: 47px;
    }
    padding-left: 24px;
    z-index: 2;
    ${fontSizesCSS.Title3};
`;

export const ActionButtonsWrapper = styled.span`
    width: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
    svg {
        padding: 0;
        opacity: 0.7;
        &:hover {
            opacity: 1;
        }
    }
`;

export const TEMPLATE_SIZES = {
    '16X9_M': {
        width: '290px',
        height: '163px'
    },
    '16X9_S': {
        width: '188px',
        height: '106px'
    },
    '2X3': {
        width: '157px',
        height: '235px'
    },
    '1X1_SQUARED': {
        width: '157px',
        height: '157px'
    },
    '1X1_ROUNDED': {
        width: '157px',
        height: '157px'
    },
    BANNER_XXL: {
        width: '1208px',
        height: '560px'
    },
    BANNER_XL: {
        width: '1208px',
        height: '336px'
    },
    BANNER_L: {
        width: '596px',
        height: '336px'
    },
    BANNER_M: {
        width: '392px',
        height: '220px'
    },
    BANNER_S: {
        width: '230px',
        height: '345px'
    },
    BUTTON: {
        width: '120px',
        height: '36px'
    },
    UNKNOWN: {
        width: '100px',
        height: '100px'
    }
};

export const TOP_TEN_NUMBER_SIZES = {
    '16X9_M': {
        fontSize: '170px',
        marginBottom: '12px',
        marginBottomInfinity: '0px'
    },
    '16X9_S': {
        fontSize: '120px',
        marginBottom: '20px',
        marginBottomInfinity: '8px'
    },
    '2X3': {
        fontSize: '210px',
        marginBottom: '8px',
        marginBottomInfinity: '-14px'
    },
    '1X1_SQUARED': {
        fontSize: '180px',
        marginBottom: '12px',
        marginBottomInfinity: '0px'
    },
    '1X1_ROUNDED': {
        fontSize: '180px',
        marginBottom: '12px',
        marginBottomInfinity: '0px'
    },
    BANNER_XXL: {
        fontSize: '380px',
        marginBottom: '-12px',
        marginBottomInfinity: '-48px'
    },
    BANNER_XL: {
        fontSize: '380px',
        marginBottom: '-8px',
        marginBottomInfinity: '-48px'
    },
    BANNER_L: {
        fontSize: '380px',
        marginBottom: '-4px',
        marginBottomInfinity: '-42px'
    },
    BANNER_M: {
        fontSize: '210px',
        marginBottom: '12px',
        marginBottomInfinity: '-8px'
    },
    BANNER_S: {
        fontSize: '380px',
        marginBottom: '-14px',
        marginBottomInfinity: '-52px'
    },
    BUTTON: {
        fontSize: '80px',
        marginBottom: '-10px',
        marginBottomInfinity: '-18px'
    },
    UNKNOWN: {
        fontSize: '100px',
        marginBottom: '12px',
        marginBottomInfinity: '0px'
    }
};

export const PROVIDER_CARD_SIZES = {
    '16X9_M': {
        width: '163px',
        height: '163px',
        margin: '16px'
    },
    '16X9_S': {
        width: '106px',
        height: '106px',
        margin: '16px'
    },
    '2X3': {
        width: '157px',
        height: '235px',
        margin: '16px'
    },
    '1X1_SQUARED': {
        width: '157px',
        height: '157px',
        margin: '16px'
    },
    '1X1_ROUNDED': {
        width: '157px',
        height: '157px',
        margin: '16px'
    },
    BANNER_XXL: {
        width: '560px',
        height: '560px',
        margin: '36px'
    },
    BANNER_XL: {
        width: '336px',
        height: '336px',
        margin: '24px'
    },
    BANNER_L: {
        width: '336px',
        height: '336px',
        margin: '24px'
    },
    BANNER_M: {
        width: '220px',
        height: '220px',
        margin: '24px'
    },
    BANNER_S: {
        width: '230px',
        height: '345px',
        margin: '24px'
    },
    UNKNOWN: {
        width: '100px',
        height: '100px',
        margin: '16px'
    }
};

export const TopNumber = styled.div`
    line-height: 1;
    color: #1a1a1a;
    font-weight: 800;
    margin-left: 8px;
    margin-right: -8px;
    align-self: flex-end;
    text-shadow: 1px 0 #868686, -1px 0 #868686, 0 1px #868686, 0 -1px #868686, 1px 1px #868686, -1px -1px #868686, 1px -1px #868686,
        -1px 1px #868686;
`;
export const TopTenNumber = styled(TopNumber)<{ $isInfinite?: boolean; template: TopTenNumberSizeKey }>`
    font-size: ${(props) => TOP_TEN_NUMBER_SIZES[props.template]?.fontSize || TOP_TEN_NUMBER_SIZES['UNKNOWN'].fontSize};
    margin-bottom: ${({ $isInfinite, template }) =>
        $isInfinite
            ? TOP_TEN_NUMBER_SIZES[template]?.marginBottomInfinity || TOP_TEN_NUMBER_SIZES['UNKNOWN'].marginBottomInfinity
            : TOP_TEN_NUMBER_SIZES[template]?.marginBottom || TOP_TEN_NUMBER_SIZES['UNKNOWN'].marginBottom};

    ${({ template }) => template === 'BUTTON' && 'margin-right:4px'};
`;

export const TopNumber16x9 = styled(TopNumber)<{ $isInfinite?: boolean }>`
    margin-bottom: ${({ $isInfinite }) => ($isInfinite ? '-4px' : '18px')};
`;

export type TemplateSizeKey = keyof typeof TEMPLATE_SIZES;
export type TopTenNumberSizeKey = keyof typeof TOP_TEN_NUMBER_SIZES;
export type ProviderCardSizeKey = keyof typeof PROVIDER_CARD_SIZES;

export const CardCollectionIndicator = styled.div`
    position: absolute;
    left: 12px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 24px;
    border-radius: 12px;
    background: ${theme.palette.background.main};
    text-align: center;
    cursor: default;
    z-index: 2;
    svg {
        &:hover {
            cursor: default;
        }
    }
`;

export const DeactivatedCardOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
`;

export const CardTypeIndicator = styled.div`
    z-index: 1;
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: auto;
    min-width: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 8px;
    height: 24px;
    background: ${theme.palette.background.main};
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: ${theme.palette.black.main};
    cursor: default;
    svg {
        &:hover {
            cursor: default;
        }
    }
`;

export const CardTypeSeparator = styled.div`
    width: 1px;
    height: 16px;
    background-color: ${theme.palette.black.main};
`;
export const TemplateCardBox = styled.div<{ $type: TemplateSizeKey }>`
    position: relative;
    background-color: ${theme.palette.text.main};
    border: 2px dashed ${theme.palette.background.innerBorder};
    box-sizing: border-box;
    width: ${({ $type }) => TEMPLATE_SIZES[$type]?.width || TEMPLATE_SIZES['UNKNOWN']?.width};
    height: ${({ $type }) => TEMPLATE_SIZES[$type]?.height || TEMPLATE_SIZES['UNKNOWN']?.height};
    border-radius: ${({ $type }) => ($type === '1X1_ROUNDED' ? '50%' : '4px')};
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        cursor: pointer;
    }
`;

export const CardText = styled.div<{ color?: string }>`
    color: ${(props) => (props.color ? `${props.color}` : theme.palette.white.main)};
`;

// used in places where translation tooltip needs space and text has to be truncated correctly
export const TruncatingTextHolder = styled.span<{ twoLine?: boolean }>`
    max-width: calc(100% - 36px);
    width: auto;
    ${({ twoLine }) =>
        twoLine
            ? css`
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  overflow-wrap: break-word;
              `
            : css`
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
              `}
`;

export const CardTitle = styled(CardText)`
    ${fontSizesCSS.Caption2};
    display: flex;
    align-items: center;
    gap: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    svg {
        path {
            fill: ${theme.palette.white.main};
        }
    }
`;
export const CardSubtitle = styled(CardText)`
    opacity: 0;
    position: absolute;
    ${fontSizesCSS.Caption3}
    display: flex;
    align-items: center;
    gap: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    svg {
        path {
            fill: ${theme.palette.white.main};
        }
    }
`;

export const TemplateCardContainer = styled.div<{
    $type: TemplateSizeKey;
    $isDisabled?: boolean;
    $isFirst?: boolean;
    $isCollection?: boolean;
}>`
    display: inline-block;
    svg {
        width: ${({ $type }) => ($type === 'BUTTON' ? '13px' : '32px')};
        height: ${({ $type }) => ($type === 'BUTTON' ? '13px' : '32px')};
        opacity: 0.7;
        ${(props) => props.$isDisabled && 'cursor: unset'};
    }

    ${(props) =>
        !props.$isDisabled &&
        css`
            &:hover {
                cursor: pointer;
                ${TemplateCardBox} {
                    border: 2px dashed ${theme.palette.white.main};
                }
                svg {
                    opacity: 1;
                }
            }
        `};

    ${({ $isFirst, $isCollection }) => !$isFirst && !$isCollection && 'margin-left: 16px'};
    ${({ $isCollection }) => $isCollection && 'margin-right: 16px'};
    ${({ $type }) => $type === 'BUTTON' && 'margin-top: 36px'};
`;

export const LogoContainer = styled.div<{ gradientsValues?: string }>`
    position: absolute;
    width: 100%;
    height: 30%;
    top: 0;
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: ${({ gradientsValues }) => `linear-gradient(187deg,#000,${gradientsValues},transparent 66%);`};
        opacity: 0.7;
    }
`;

export const ProviderLogo = styled.div<{ $logo: string }>`
    background: url(${(props) => props.$logo});
    z-index: 1;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 25%;
    height: 50%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;

export const CardWrapper = styled.div<{ $type: TemplateSizeKey; $extraMargin?: boolean; $itemAdminLocked?: boolean }>`
    display: inline-block;
    position: relative;
    width: ${({ $type }) => TEMPLATE_SIZES[$type]?.width || TEMPLATE_SIZES['UNKNOWN']?.width};
    height: ${({ $type }) => `calc( ${TEMPLATE_SIZES[$type]?.height || TEMPLATE_SIZES['UNKNOWN']?.height} +  36px)`};
    vertical-align: top;

    ${CardCollectionIndicator} {
        ${({ $type }) => $type === '1X1_ROUNDED' && 'top: 12px'};
        ${({ $type }) => $type === '1X1_ROUNDED' && 'left: 50%'};
        ${({ $type }) => $type === '1X1_ROUNDED' && 'transform: translateX(-50%)'};
    }

    ${CardTypeIndicator} {
        ${({ $type }) => $type === '1X1_ROUNDED' && 'right: 50%'};
        ${({ $type }) => $type === '1X1_ROUNDED' && 'transform: translateX(50%)'};
        svg {
            width: 16px;
            height: 16px;
        }
        ${({ $itemAdminLocked }) =>
            $itemAdminLocked
                ? `svg:not(:first-child) path {
            fill: ${theme.palette.black.main};
        }`
                : `svg path {
            fill: ${theme.palette.black.main};
        }`}
    }

    &:hover {
        ${CardSubtitle} {
            opacity: 0.7;
        }
    }
`;

export const ButtonCardWrapper = styled(CardWrapper)``;

export const CardInfo = styled.div<{ $type: TemplateSizeKey }>`
    width: ${({ $type }) => TEMPLATE_SIZES[$type]?.width || TEMPLATE_SIZES['UNKNOWN']?.width};
    position: absolute;
    overflow: hidden;
    bottom: 0;
    right: 0;
`;
export const CardActions = styled.div<{ $background?: string }>`
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ $background }) => $background || theme.palette.background.hovered};
    display: none;
    transition: display 150ms ease-in-out;
`;

export const DragContainer = styled.div`
    position: absolute;
    left: 0;
    svg {
        cursor: move;
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const CardPresenter = styled(TemplateCardBox)<{ $background?: string; $disabled?: boolean }>`
    border: unset;
    ${(props) =>
        props.$background &&
        `background: ${props.$background} ${
            props.$background.includes('static/media/notAvailableLogo') ? `, ${theme.palette.text.main}` : ''
        }`};
    background-position: center;
    background-size: cover;
    position: absolute;
    cursor: ${(props) => (props.$disabled ? 'unset' : 'move')};
    right: 0;
    bottom: 36px;

    ${ProviderLogo} {
        ${({ $type }) => $type === '1X1_ROUNDED' && 'right: 25%'};
    }

    &:hover {
        ${CardActions} {
            z-index: 1;
            border: 2px solid ${theme.palette.white.main};
            box-sizing: border-box;
            border-radius: ${({ $type }) => ($type === '1X1_ROUNDED' ? '50%' : '4px')};
            display: flex;
            align-items: center;
            justify-content: center;
            ${ActionsContainer} {
                flex-direction: ${({ $type }) =>
                    ['16X9_S', '16X9_M', '1X1_SQUARED', '1X1_ROUNDED', 'BANNER_S', 'UNKNOWN', 'GALLERY'].includes($type)
                        ? 'row'
                        : 'column'};
                align-items: center;
            }
        }
    }
`;
export const ButtonCardPresenter = styled(TemplateCardBox)<{ disabled?: boolean }>`
    cursor: move;
    margin-top: 36px;
    button {
        min-width: 120px;
        z-index: 1;
    }

    &:hover {
        z-index: 1;
        ${CardActions} {
            top: -72px;
            display: flex;
            align-items: center;
            justify-content: center;
            ${ActionsContainer} {
                z-index: 2;
                flex-direction: row;
                align-items: center;
            }
        }
        button {
            border: 2px solid ${theme.palette.white.main};
            ${(props) =>
                props.disabled &&
                css`
                    &:hover {
                        cursor: initial;
                    }
                `}
        }
    }
`;

export const DividerDiv = styled.div`
    width: 2px;
    height: 24px;
`;

export const SVGAddIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 2px;
    svg {
        width: 16px;
        height: 16px;
    }
`;
export const SVGRoundIconContainer = styled(SVGAddIconContainer)``;

export const CardSeparatorContainer = styled.div<{ $type: TemplateSizeKey }>`
    width: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
        path {
            fill: ${theme.palette.background.main}
            opacity:  0.5;
        }
    }
    ${DividerDiv} {
        background:  ${theme.palette.background.main};
        opacity:  0.5;
        height: ${({ $type }) => ($type === 'BUTTON' ? '10px' : '24px')};
    }
    ${SVGAddIconContainer} {
        display: none;
    }
    ${SVGRoundIconContainer} {
        display: flex;
    }

    &:hover {
        svg {
            background: ${theme.palette.primary.main};
            path {
                fill: ${theme.palette.white.main};
                opacity:  1;
            }
        }
        ${DividerDiv} {
            background: ${theme.palette.primary.main};
            opacity: 1;
        }
        ${SVGAddIconContainer} {
            display: flex;
        }
        ${SVGRoundIconContainer} {
            display: none;
        }
    }
    ${({ $type }) => $type !== 'BUTTON' && 'gap:8px;'};
`;

export const CardSeparatorWrapper = styled.div<{ $type: TemplateSizeKey }>`
    margin-bottom: ${({ $type }) => ($type === 'BUTTON' ? '-32px' : '36px')};
    align-self: center;
    cursor: pointer;
`;

export const PreviewOrGalleryViewContainer = styled.div<{ noBottomMargin?: boolean }>`
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    z-index: 2;
    height: 231px;
    ${AddLogoIcon} {
        height: 40px;
        width: 40px;
    }
    margin-bottom: ${(props) => (props.noBottomMargin ? '0' : '64px')};
`;

export const FieldsContainer = styled.div<{ isGallery?: boolean }>`
    display: flex;
    ${({ isGallery }) => isGallery && 'width:60%'};
    ${({ isGallery }) => !isGallery && 'flex:1'};
    flex-direction: column;
    gap: 12px;
    padding-left: 24px;
`;

export const GalleryField = styled.div<{ color?: string }>`
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 40%;
    div {
        margin: 0;
        min-height: unset;
    }
    .MuiInputBase-root {
        border: none;
    }

    .MuiInputBase-input {
        background-color: transparent;
        padding: 0;
        color: ${(props) => (props.color ? props.color : theme.palette.white.main)};
    }

    .MuiIconButton-root {
        min-width: 64px;
    }

    ${TranslationTooltipIconContainer} {
        svg {
            width: 18px;
            height: 18px;
        }
    }
`;

export const TitleField = styled(GalleryField)`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 55px;
    ${fontSizesCSS.EditorialTitle};
    .MuiInputBase-input {
        height: 55px;
        ${fontSizesCSS.EditorialTitle};
    }
`;
export const SubtitleField = styled(GalleryField)`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 33px;
    ${fontSizesCSS.EditorialSubtitle}
    .MuiInputBase-input {
        height: 33px;
        ${fontSizesCSS.EditorialSubtitle};
    }
`;
export const DescriptionField = styled(GalleryField)<{ twoLine?: boolean }>`
    width: 100%;
    max-height: ${(props) => (props.twoLine ? '3em' : '28px')};
    ${fontSizesCSS.EditorialDescription};
    overflow: hidden;
    text-overflow: ellipsis;
    .MuiInputBase-input {
        height: 28px;
        ${fontSizesCSS.EditorialDescription};
    }
`;

export const BackgroundDropZone = styled.div`
    height: 100%;
    width: 40%;
    position: absolute;
    right: 0;
`;

export const BackgroundDropZoneCM = styled.div`
    position: absolute;
    height: 60%;
    width: 50%;
    top: 0;
    right: 0;
`;

export const DragAndDropTextWrapper = styled.div`
    color: ${theme.palette.white.main};
    font-size: 15px;
    width: 240px;
    position: absolute;
    left: 60px;
    opacity: 0;
    transition: opacity 0.1s linear;
`;

export const BackgroundSelectionContainer = styled.div`
    background-color: transparent;
    z-index: 99;
    padding: 8px;
    position: absolute;
    display: flex;
    align-items: center;
    border-radius: 38px;
    top: 40%;
    right: calc(30% - 165px);
    width: 44px;
    height: 44px;
    overflow: hidden;
    transition: width 0.5s, right 0.5s, background-color 0.5s, padding 0.5s;
    &:hover {
        background-color: ${theme.palette.background.smallBorder};
        cursor: pointer;
        right: calc(30% - 300px);
        width: 290px;
        transition: width 0.5s, right 0.5s, background-color 0.5s, padding 0.5s;
        ${DragAndDropTextWrapper} {
            transition: opacity 0.2s linear 0.3s;
            opacity: 1;
        }
    }
`;
export const BackgroundSelectionButton = styled.div`
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 44px;
        path {
            fill: black;
        }
    }
`;
export const ContentHolder = styled.div<{ marginTop?: string }>`
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
    padding: 20px;
    height: 210px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

// TODO:() After the V2 approach is tested an accepted as better looking, that should be renamed and this one deleted
export const GradientBackgroundToTop = styled.div<{ gradientsValues?: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: ${({ gradientsValues }) => `linear-gradient(to top,#000,${gradientsValues},transparent 100%)`};
    border-radius: 4px;
`;
export const GradientBackgroundToRight = styled.div<{ gradientsValues?: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: ${({ gradientsValues }) => `linear-gradient(to right,#000,${gradientsValues},transparent 100%)`};
    border-radius: 4px;
`;

export const GradientBackgroundToTopV2 = styled.div<{ black: number; transparent: number }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: ${({ black, transparent }) => `linear-gradient(to top, #000 ${black}%, transparent ${transparent}%)`};
    border-radius: 4px;
`;

export const GradientBackgroundToRightV2 = styled.div<{ black: number; transparent: number }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: ${({ black, transparent }) => `linear-gradient(to right, #000 ${black}%, transparent ${transparent}%)`};
    border-radius: 4px;
`;

/* Collection */

export const CollectionPlaceholderModule = styled.div<{ background?: string; isAdvanced?: boolean }>`
    margin: 23px 0;
    padding: 36px 0;
    border-radius: 5px;
    background: ${(props) => (props.background ? props.background : theme.palette.black.main)};
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: ${(props) => (props.isAdvanced ? '763px' : '483px')};
    max-width: calc(100vw - 96px);
    overflow: hidden;
`;

export const PlaceholderActions = styled.div`
    width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    svg {
        cursor: pointer;
    }
`;

export const CollectionContainer = styled.div`
    width: calc(100% - 96px);
    height: 100%;
`;

export const CollectionTitle = styled(CardText)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 45px;
    ${fontSizesCSS.Title3};
    svg {
        path {
            fill: ${theme.palette.white.main};
        }
        cursor: pointer;
    }
    div {
        position: absolute;
        top: 0;
        left: 0;
    }
`;
export const CollectionItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 188px;
    height: 106px;
    border-radius: 6px;
    background: ${theme.palette.grey.dark};
    span {
        flex: 1;
    }
`;
export const CollectionGrid = styled.div`
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
`;

// will not delete this for now, might be needed at some point
export const CollectionIconContainer = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    align-items: end;
    div {
        width: 32px;
        height: 24px;
        margin: 0 0 12px 12px;
        border-radius: 12px;
        background: ${theme.palette.background.main};
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const CollectionWrapper = styled.div<{ $height: number }>`
    max-height: ${(props) => props.$height}px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 16px;

    ${EditorialCustomizeVisualContainer} {
        position: absolute;
        height: 50%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
`;

export const CollectionCardWrapper = styled(CardWrapper)<{ $empty?: boolean; $isDynamic?: boolean }>`
    margin: ${(props) => (props.$extraMargin ? '0 16px 16px 0 ' : '0 0 16px 0')};
    height: ${({ $type }) => TEMPLATE_SIZES[$type]?.height || TEMPLATE_SIZES['UNKNOWN']?.height};
    ${CardPresenter} {
        ${(props) => props.$isDynamic && 'cursor: default'};
        bottom: unset;
        ${(props) =>
            props.$empty &&
            css`
                cursor: default;
                background: ${theme.palette.background.hovered};
                border: 2px dashed ${theme.palette.background.innerBorder};
            `}
    }
`;

export const CollectionCustomizeOverlay = styled.div`
    background: ${theme.palette.black.main};
    opacity: 0.25;
    position: absolute;
    inset: 0;
`;

/* Searcth */

export const SearchTitle = styled(CardText)`
    height: 94px;
    ${fontSizesCSS.Title3};
    color: ${(props) => (props.color ? props.color : theme.palette.white.main)};
    div {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

export const SearchIconContainer = styled.div`
    padding-left: 26px;
`;

export const PlaceholderModule = styled.div<{ $extraHeight?: boolean }>`
    padding: 36px 0;
    background: ${theme.palette.black.main};
    border-radius: 5px;
    min-height: ${(props) => (props.$extraHeight ? '730px' : '522px')};
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;

export const PlaceholderWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    div {
        position: absolute;
        top: calc(50% + 28px);
    }
    .MuiTooltip-popper {
        top: 50%;
    }
`;
export const PlaceholderImage = styled.img``;

export const PlaceholderModuleAction = styled.div`
    margin-left: 36px;
    min-width: 72px;
    height: 72px;
    border-radius: 50%;
    background: ${theme.palette.background.innerBorder};
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 24px;
        height: 24px;
    }
`;

export const ProviderLogoCardBox = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const ProviderLogoImg = styled.img`
    height: auto;
`;

export const ProviderLogoCardContainer = styled.div<{ $type: ProviderCardSizeKey; $provider: providerLogos }>`
    position: relative;
    box-sizing: border-box;
    background-color: ${({ $provider }) =>
        $provider === 'prime_video'
            ? theme.palette.background.providers.amazon
            : $provider === 'horizon_sports'
            ? theme.palette.background.providers.horizonSports
            : $provider === 'motor_racing'
            ? theme.palette.background.providers.motorRacing
            : theme.palette.background.hover};
    width: ${({ $type }) => PROVIDER_CARD_SIZES[$type]?.width || PROVIDER_CARD_SIZES['UNKNOWN']?.width};
    height: ${({ $type }) => PROVIDER_CARD_SIZES[$type]?.height || PROVIDER_CARD_SIZES['UNKNOWN']?.height};
    border-radius: ${({ $type }) => ($type === '1X1_ROUNDED' ? '50%' : '4px')};
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    ${ProviderLogoImg} {
        width: ${({ $provider, $type }) =>
            $provider.includes('youtube')
                ? `calc(100% - 2 * ${PROVIDER_CARD_SIZES[$type]?.margin || PROVIDER_CARD_SIZES['UNKNOWN']?.margin})`
                : '100%'};
    }
`;
